import React from "react";
import "./Header.css";
import CustomButton from "../../../components/buttons/CustomButton";
import SearchComponent from "../../../components/search/SearchComponent";
import IconPerson from '@mui/icons-material/PersonAddAlt1Outlined';
import AlphabeticList from "../filter/AlphabeticList";
import AreaTitle from "../../../components/areaTitle/AreaTitle";

function Header(props) {
    const { OnCreatePatient, OnSearch } = props;
    const [searchValue, setSearchValue] = React.useState("");

    function OnCreatePatientClickHandler() {
        OnCreatePatient();
    };

    function OnSearchUpdateHandler(value) {
        let newValue = value.trim();
        setSearchValue(newValue);
        OnSearch(newValue);
    }

    function AllPatientsHandler() {
        OnSearchUpdateHandler("")
    }

    return (
        <div className="patients-header-container">
            <div style={{display:"flex", gap:"10px", flex:"1", justifyContent:"space-between"}}>
                <AreaTitle Title="Lista de pacientes" />
                <CustomButton variant="contained" style="primary" label={"Novo paciente"} icon={<IconPerson />} onClick={OnCreatePatientClickHandler} />
            </div>
            <div className="patients-header">
                <SearchComponent OnUpdate={OnSearchUpdateHandler} />
                {/*
                <div style={{ display: "flex", flexDirection: "row", gap: "20px", alignItems:"center" }}>
                    <CustomButton variant={searchValue == "" ? "contained" : "outlined"} style="secondary" label={"Todos"} onClick={AllPatientsHandler} />
                    <AlphabeticList currentValue={searchValue} onSelect={OnSearchUpdateHandler} />
                </div>
                */}
            </div>
        </div>
    );
}

export default Header;