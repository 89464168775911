import React from 'react';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import NotInterestedOutlinedIcon from '@mui/icons-material/NotInterestedOutlined';
import ColorPalette from '../../../../../colorpalette/ColorPalette';
import {GetFriendlyCPF} from '../../../../../utils/Utils';
import { DataProviderInstance } from '../../../../../api/DataProvider';

const ListItem = ({ id, date, name, cpf, amountCharged,  invoiceData }) => {

    const {UpdateInvoiceNfIssued } =  DataProviderInstance();

    const time = new Date(date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const formattedDate = new Date(date).toLocaleDateString('pt-BR');

    const [issuedDocument, setIssuedDocument] = React.useState(invoiceData && invoiceData.issuedDocument == "1" ? true : false);

    const getCheckedItem = () => {
        return <CheckOutlinedIcon htmlColor={ColorPalette.grayDark} fontSize='verysmall'/>;
    }

    const getUncheckedItem = () => {
        return <NotInterestedOutlinedIcon htmlColor={ColorPalette.red} fontSize='verysmall'/>;
    }

    const toggleIssuedDocument = () => {
        setIssuedDocument(!issuedDocument);
        UpdateInvoiceNfIssued(invoiceData.id, !issuedDocument);
    }

    return (
        <tr key={id} >
            <td>{formattedDate}</td>
            <td>{name}</td>
            <td>{GetFriendlyCPF(cpf)}</td>
            {invoiceData ?  <td>{invoiceData.price}</td>:<td></td>}
            <td>{invoiceData != null? getCheckedItem() : getUncheckedItem() }</td>
            {invoiceData ?  <td>{invoiceData.issueDocument == "1" ? getCheckedItem() : getUncheckedItem()}</td>:<td></td>}
            {invoiceData != null && invoiceData.issueDocument == "1" ? <td><div style={{cursor:"pointer", width:"fit-content"}} onClick={toggleIssuedDocument}>{issuedDocument == true ? getCheckedItem() : getUncheckedItem()}</div></td> : <td></td>}
        </tr>
    );
};

export default ListItem;