const API_URL = "https://api.whatsapp.com/send";

const SendWhatsAppMessage = (phone, message) =>
{
    const fixedPhone = phone.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
    const countryCode = '55';
    const finalPhone = countryCode + fixedPhone;

    const url = API_URL + '?phone=' + finalPhone + '&text=' + message;

    window.open(url, '_blank', 'noreferrer');
}

export {SendWhatsAppMessage};