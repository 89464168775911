import React from 'react';
import { useState, useEffect } from 'react';
import PopupBase from "../base/PopupBase";
import { DataProviderInstance } from '../../api/DataProvider';
import CalendarConfig from '../../consts/CalendarConfig';
import AppointmentsState from "../../consts/AppointmentsState";
import CustomButton from "../../components/buttons/CustomButton";
import IconBlocked from '@mui/icons-material/BlockOutlined';
import IconDelete from '@mui/icons-material/DeleteOutline';
import TextField from '@mui/material/TextField';
import Header from "../AppointmentPopupContent/header/Header";
import { MenuItem, Select, FormControl, InputLabel, FormControlLabel, Switch } from '@mui/material';

function BlockAppointmentPopupContent(props) {

    const getInitialEndHour = () => {
        let [endHour, endMinute] = [initialHour + 1, initialMinute];
        return String(endHour).padStart(2, '0');
    }

    const getDefaultEndMiniute = () => {
        return initialHour === getInitialEndHour() ? '01' : '00';
    }

    const { Data, OnChange, OnHide } = props;
    const { SetAppointment, UpdateAppointment, DeleteAppointment } = DataProviderInstance();
    const [reason, setReason] = useState('');

    const [rawDate, rawTime] = Data.date.split(" ");
    const [initialHour, initialMinute] = rawTime.split(":").map(Number);

    const [endHour, setEndHour] = useState(getInitialEndHour());
    const [endMinutes, setEndMinutes] = useState(getDefaultEndMiniute());
    const [duration, setDuration] = useState('00:00:00');

    const minuteRef = React.useRef(null);

    useEffect(() => {

        updateDuration();

    }, [endMinutes]);

    useEffect(() => {

        if (initialHour == endHour) {
            setEndMinutes('01')
        } else {
            setEndMinutes('00');
        }

        updateDuration();

    }, [endHour]);

    const updateDuration = () => {
        let durationHours = (endHour - initialHour) + "";
        let durationMinutes = (endMinutes - initialMinute) + "";
        let durationSeconds = "00";

        let durationStr = `${String(durationHours).padStart(2, '0')}:${String(durationMinutes).padStart(2, '0')}:${String(durationSeconds).padStart(2, '0')}`;
        setDuration(durationStr);
    }

    function OnCloseClickHandler() {
        Hide();
    }

    function Hide() {
        OnHide();
    }

    function OnPositiveButtonClickHandler() {

        if (duration === "00:00:00") {
            alert("Selecione um horário de término válido");
            return;
        }

        CreateBlockAppointment();
        Hide();
    }

    function OnDeleteHandler() {
        DeleteBlockAppointment();
        Hide();
    }

    function DeleteBlockAppointment() {
        DeleteAppointment(Data.id, response => {
            if(OnChange) OnChange(response);
        });
    }

    function CreateBlockAppointment() {
        var date = CalendarConfig.ISOToDate(Data.date);
        var parsedDate = CalendarConfig.GetAAAAMMDD(date);
        var time = CalendarConfig.GetTime(Data.date);

        CreateAppointmentData(
            Data.id,
            AppointmentsState.Blocked,
            parsedDate,
            time,
            duration,
            reason,
            "",
            "",
        );
    }

    function CreateAppointmentData(id, status, date, time, duration, patient, resource, procedure) {
        var data = {
            id: id,
            status: status,
            date: date + " " + time,
            patient: patient,
            resource: resource,
            duration: duration,
            procedure: null,
            price: (procedure) ? procedure.Price : null,
        }

        // Create or update a appointment;
        if (id == null) {
            SetAppointment(data, (response) => {
                
                if(OnChange) OnChange(response);
            });
        }
        else {
            UpdateAppointment(data, (response) => {
                
                if(OnChange) OnChange(response);
            });
        }

        return data;
    }

    function GetTitle() {
        if (Data.id == null) {
            return "Bloquear horário";
        }
        else {
            return "Horário bloqueado";
        }
    }

    function GetFooterButtons() {
        if (Data.id == null) {
            return <CustomButton variant="contained" style="primary" label={"Bloquear"} icon={<IconBlocked />} onClick={OnPositiveButtonClickHandler} />
        }
        else {
            return <CustomButton variant="contained" style="warning" label={"Excluir bloqueio"} icon={<IconDelete />} onClick={OnDeleteHandler} />
        }
    }

    const handleHoursChange = (event) => {
        setEndHour(event.target.value);
    };

    const handleMinutesChange = (event) => {
        setEndMinutes(event.target.value);
    };

    function GetContent() {

        let dateStr = CalendarConfig.GetDDMMAAAA(Data.date);
        let date = CalendarConfig.GetFullDateName(dateStr);
        let time = CalendarConfig.GetTime(Data.date);

        if (Data.id == null) {
            return (
                <div style={{ display: "flex", flex: "1", flexDirection: "column", gap: "20px" }}>
                    <Header Date={Data.date} />
                    <div style={{ display: "flex", flex: "1", flexDirection: "row", gap: "10px", alignItems: "center" }}>
                        <span style={{ width: "350px" }}>Horário de término:</span>
                        <FormControl fullWidth >
                            <InputLabel id="hours-label">Horas</InputLabel>
                            <Select
                                labelId="hours-label"
                                defaultValue={endHour}
                                value={endHour}
                                onChange={handleHoursChange}
                                error={!endHour || endHour < initialHour}
                            >
                                {[...Array(24).keys()].filter(hour => hour > initialHour || (hour === initialHour && initialMinute === 0)).map((hour) => (
                                    <MenuItem key={hour} value={String(hour).padStart(2, '0')}>
                                        {String(hour).padStart(2, '0')}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id="minutes-label">Minutos</InputLabel>
                            <Select
                                labelId="minutes-label"
                                defaultValue={endMinutes}
                                value={endMinutes}
                                onChange={handleMinutesChange}
                                error={endMinutes == null}
                            >
                                {[...Array(60).keys()].filter(minute => endHour > initialHour || minute > initialMinute).map((minute) => (
                                    <MenuItem key={minute} value={String(minute).padStart(2, '0')}>
                                        {String(minute).padStart(2, '0')}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <TextField multiline rows={5} label="Motivo" value={reason} onChange={(e) => setReason(e.target.value)} />
                </div>
            );
        }
        else {
            let reason = Data.patient;
            return (
                <div style={{ display: "flex", flex: "1", flexDirection: "column", gap: "30px" }}>
                    <div style={{ display: "flex", flex: "1", flexDirection: "column", gap: "10px" }}>
                        <span>{date}</span>
                        <span>{time}</span>
                    </div>
                    <div style={{ display: "flex", flex: "1", flexDirection: "column", gap: "6px" }}>
                        <b>Motivo:</b>
                        <p>{reason}</p>
                    </div>
                </div>
            );
        }
    }

    function GetRender() {
        return <PopupBase Title={GetTitle()} Content={GetContent()} Footer={GetFooterButtons()} OnClose={OnCloseClickHandler} />
    }

    return (GetRender());
}

export default BlockAppointmentPopupContent;