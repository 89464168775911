import React from "react";
import "./SearchComponent.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarsStaggered, faSearch } from "@fortawesome/free-solid-svg-icons";

function SearchComponent(props)
{
    const { OnUpdate } = props;

    function OnChangeHandler(event)
    {
        OnUpdate(event.target.value);
    }

    return(
        <div className="search-component">
            <FontAwesomeIcon icon={faSearch} color="#1F1F1F" />
            <input type="text" placeholder="Buscar" onChange={OnChangeHandler}/>
        </div>
    )
}

export default SearchComponent;