import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './AppointmentHistoryItem.module.css';
import CustomButtom from '../../../../../../components/buttons/CustomButton';
import { DataProviderInstance } from "../../../../../../api/DataProvider";
import { Visibility } from '@mui/icons-material';
import AppointmensState from '../../../../../../consts/AppointmentsState';

const AppointmentHistoryItem = ({ data }) => {

    const { CompanyConfig } = DataProviderInstance();
    const navigate = useNavigate();

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const formatTime = (dateString) => {
        const date = new Date(dateString);
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    const handleViewDetails = () => {
        navigate(`/appointment/${data.id}`);
    }

    return (
        <div className={styles.container}>
            <div className={styles.infos}>
                <p>{formatDate(data.date)}</p>
                <p>{formatTime(data.date)}</p>
                <p>{AppointmensState.GetLabel(data.status)}</p>
                <p>{CompanyConfig.Procedures.find(item => item.Id == data.procedure)?.Label}</p>
                <p>{CompanyConfig.Resources.find(item => item.Id == data.resource)?.Label}</p>
            </div>
            <CustomButtom style={"secondary"} variant="outlined" icon={<Visibility />} onClick={handleViewDetails} />
        </div>
    );
};

export default AppointmentHistoryItem;