import ColorPalette from '../colorpalette/ColorPalette';
import IconBlocked from '@mui/icons-material/BlockOutlined';
import IconScheaduled from '@mui/icons-material/ScheduleOutlined';
import IconRescheduled from '@mui/icons-material/RecyclingOutlined';
import IconConfirmed from '@mui/icons-material/HowToReg';
import IconConfirmedByPatient from '@mui/icons-material/HowToReg';
import IconWaiting from '@mui/icons-material/EventSeat';
import IconDoing from '@mui/icons-material/MedicalServicesOutlined';
import IconDone from '@mui/icons-material/DoneAll';
import IconCanceled from '@mui/icons-material/Clear';
import IconCanceledByPatient from '@mui/icons-material/Clear';
import IconMissed from '@mui/icons-material/PersonOffOutlined';

export const Available = "available";
export const Scheduled = "scheduled";
export const Rescheduled = "rescheduled";
export const Confirmed = "confirmed";
export const ConfirmedByPatient = "confirmed_by_patient"; 
export const Waiting = "waiting"; 
export const Doing = "doing"; 
export const Done = "completed";
export const Canceled = "canceled";
export const CanceledByPatient = "canceled_by_patient";
export const Missed = "noshow";
export const Blocked = "blocked";
export const Unavailable = 'unavailable';

const AppointmensState = {
    Available: Available,
    Scheduled: Scheduled,
    Rescheduled: Rescheduled,
    Confirmed: Confirmed,
    ConfirmedByPatient: ConfirmedByPatient,
    Waiting: Waiting,
    Doing: Doing,
    Done: Done,
    Canceled: Canceled,
    CanceledByPatient: CanceledByPatient,
    Missed: Missed,
    Blocked: Blocked,
    Unavailable: Unavailable,
    All: [Confirmed, ConfirmedByPatient, Waiting, Doing, Done, Canceled, CanceledByPatient, Missed, Scheduled, Rescheduled, Available, Blocked, Unavailable],
    Filters: [Scheduled, Confirmed, ConfirmedByPatient, Waiting,Doing, Done, Canceled, CanceledByPatient, Missed, Blocked, Rescheduled],
    Changeable: [Scheduled, Rescheduled, ConfirmedByPatient, Confirmed, Waiting, Doing, Done, Canceled, CanceledByPatient, Missed],
    ChangeableByPatient: [Scheduled, ConfirmedByPatient, CanceledByPatient],
    ChangeableBySystem: [Scheduled, Rescheduled, Confirmed, Canceled],
    NonBlockers:[Available, Rescheduled, Canceled, CanceledByPatient, Missed],
    States: [
        {
            id: "undefined",
            label: "undefined",
            backgroundColor: "#000000",
            textColor: "#000000",
            icon: null
        },
        {
            id: Unavailable,
            label: "Indisponível",
            backgroundColor: "#f8f8f8",
            textColor: "#ffffffff",
            icon: null,
            cursor: "not-allowed",
            height: "12px",
        },
        {
            id: Available,
            label: "Disponível",
            backgroundColor: "#ffffffff",
            textColor: "#ffffffff",
            icon: null,
            flex: 3,
        },
        {
            id: Blocked,
            label: "Bloqueado",
            background: "repeating-linear-gradient(45deg, #F7FAFF, #F7FAFF 10px,  #EEF2F8 10px, #EEF2F8 20px )",
            textColor: "#000000",
            icon: <IconBlocked fontSize="verysmall"/>,
            flex: 1,
        },
        {
            id: Scheduled,
            label: "Agendado",
            backgroundColor: "#e7eaee",
            textColor: "#000000",
            borderColor: "#e7eaee",
            icon: <IconScheaduled fontSize="verysmall" color='#61657'/>,
            flex: 1,
        },
        {
            id: Rescheduled,
            label: "Reagendado",
            backgroundColor: "transparent",
            textColor: "#a1a1a1",
            borderColor: "#e7eaee",
            icon: <IconRescheduled fontSize="verysmall" color='#61657'/>,
            flex: 0.1
        },
        {
            id: ConfirmedByPatient,
            label: "Confirmado pelo paciente",
            backgroundColor: "#EDFFFF",
            textColor: ColorPalette.mainDark,            
            icon: <IconConfirmedByPatient fontSize="verysmall"/>,
            flex: 1,
        },
        {
            id: Confirmed,
            label: "Confirmado",
            backgroundColor: "#EDFFFF",
            textColor: ColorPalette.mainDark,
            icon: <IconConfirmed fontSize="verysmall"/>,
            flex: 1,

        },
        {
            id: Waiting,
            label: "Aguardando no local",
            backgroundColor: "#DCF9FF",
            textColor: "#000000",
            icon: <IconWaiting fontSize="verysmall"/>,
            flex: 1,

        },
        {
            id: Doing,
            label: "Em atendimento",
            backgroundColor: "#DCF2FF",
            textColor: "#000000",
            icon: <IconDoing fontSize="verysmall" />,
            flex: 1,
        },
        {
            id: Done,
            label: "Realizado",
            backgroundColor: "#F2FFEC",
            textColor: "#4e7c38",
            icon: <IconDone fontSize="verysmall"/>,
            flex: 1,
        },
        {
            id: Canceled,
            label: "Cancelado",
            backgroundColor: "#FFEFE4",
            textColor: "#6f4223",
            icon: <IconCanceled fontSize="verysmall"/>,
            flex: 0.1,
        },
        {
            id: CanceledByPatient,
            label: "Cancelado pelo paciente",
            backgroundColor: "#FFEFE4",
            textColor: "#6f4223",
            icon: <IconCanceledByPatient fontSize="verysmall"/>,
            flex: 0.1,

        },
        {
            id: Missed,
            label: "Falta",
            backgroundColor: "#f9c9c9",
            textColor: "#b33151",
            icon: <IconMissed fontSize="verysmall"/>,
            flex: 0.1,
        }
    ],
    GetDataFromId: function (id) {
        let state = this.States.find(s => s.id === id);
        return state ? state : this.States[0];
    },
    GetLabel: function (id) {
        let state = this.States.find(s => s.id === id);
        return state ? state.label : id;
    }
}

export default AppointmensState;