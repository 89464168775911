import React, { useEffect, useState } from 'react';
import styles from './AppointmentToConfirmItem.module.css';
import { SendWhatsAppMessage } from "../../../../api/WhatsappAPI";
import AppointmensState from "../../../../consts/AppointmentsState";
import CustomButtom from '../../../../components/buttons/CustomButton';
import IconWhatsapp from '@mui/icons-material/WhatsApp';
import MessagesTemplate from '../../../../consts/MessagesTemplate';
import CalendarConfig from '../../../../consts/CalendarConfig';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { UpdateAppointmentStatusAPI } from '../../../../api/API';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { Check } from '@mui/icons-material';

import ColorPalette from '../../../../colorpalette/ColorPalette';

function AppointmentToConfirmItem(props) {
    const { Id, patientId, Name, Date: date, Time, Phone, State, Procedure, Resource, OnSelect } = props;
    const messageStatusKey = `messageStatus-${Id}`; // Unique key for each appointment's messages

    const [state, setState] = useState(State);
    const [messageTemplateIndex, setMessageTemplateIndex] = useState(0);
    const [sentMessages, setSentMessages] = useState(getSentMessages());

    // Load the sent messages state from localStorage on component mount
    useEffect(() => {
        localStorage.setItem(messageStatusKey, JSON.stringify(sentMessages));
    }, [sentMessages]);

    // Retrieve sent messages from localStorage
    function getSentMessages() {
        const storedData = localStorage.getItem(messageStatusKey);
        return storedData ? JSON.parse(storedData) : [];
    }

    // Function to mark a message template as sent
    function markMessageAsSent(index) {
        setSentMessages(prevState => {
            const updatedMessages = [...prevState];
            if (!updatedMessages.includes(index)) {
                updatedMessages.push(index); // Add the index of the sent message
            }
            return updatedMessages;
        });
    }

    // Handle phone click
    function OnPhoneClickHandler() {
        window.location.href = `tel:${Phone}`;
    }

    // Handle cancellation message
    function SendCancellationMessageClickHandler() {
        let message = MessagesTemplate.AppointmentCancellationDueLackOfReturn;
        message = message.replaceAll("{name}", Name.split(' ')[0]);
        SendWhatsAppMessage(Phone, MessagesTemplate.ConvertToWhatsAppText(message));
    }

    // Handle the confirmation message click
    function OnAskForAppointmentConfirmClickHandler() {
        let template = MessagesTemplate.AppointmentAskForConfirmation[messageTemplateIndex];

        let message = template;
        let yyyymmdd = CalendarConfig.GetAAAAMMDDArray(date);
        let monthDay = yyyymmdd[2];
        let monthName = CalendarConfig.Months[yyyymmdd[1] - 1];
        let urlToConfirm = "https://www.maisqueagenda.app.br/api/confirm_appointment.php/?id=" + Id;

        const today = new Date();
        const appointmentDate = new Date(date);
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);

        let greetings = "";
        const currentHour = new Date().getHours();
        if (currentHour < 12) {
            greetings = "Bom dia";
        } else if (currentHour < 18) {
            greetings = "Boa tarde";
        } else {
            greetings = "Boa noite";
        }

        let when = "";
        if (appointmentDate.toDateString() === today.toDateString()) {
            when = "hoje";
        } else if (appointmentDate.toDateString() === tomorrow.toDateString()) {
            when = "amanhã";
        }

        message = message.replaceAll("{greetings}", greetings);
        message = message.replaceAll("{name}", Name.split(' ')[0]);
        message = message.replaceAll("{when}", when);
        message = message.replaceAll("{day}", monthDay);
        message = message.replaceAll("{month}", monthName);
        message = message.replaceAll("{time}", CalendarConfig.GetTime(date));
        message = message.replaceAll("{url}", urlToConfirm);

        SendWhatsAppMessage(Phone, MessagesTemplate.ConvertToWhatsAppText(message));
        markMessageAsSent(messageTemplateIndex);
    }

    // Handle status change
    const OnItemSelectHandler = (event) => {
        setState(event.target.value);
        UpdateAppointmentStatusAPI(Id, event.target.value, (data) => { });
    };

    // Handle name click to open patient's page
    const onNameClickHandler = () => {
        window.open(`/patients/${patientId}`);
    }

    // Get appointment state style
    const GetStyle = () => {
        let statusStyle = AppointmensState.GetDataFromId(state);
        return {
            backgroundColor: statusStyle.backgroundColor,
            color: statusStyle.textColor,
            fontSize: "20px"
        }
    }

    // Render action buttons and message selection
    const GetActionState = () => {
        if (state === AppointmensState.Canceled) {
            return (
                <div style={{ color: ColorPalette.red }}>
                    <CustomButtom style={"secondary"} variant="outlined" icon={<IconWhatsapp color='error' />} label={""} onClick={SendCancellationMessageClickHandler} />
                </div>
            );
        }
        else if (state === AppointmensState.Confirmed) {
            return (
                null
            )
        }
        else {
            return (
                <div className={styles.containerActions}>
                    <FormControl >
                        <Select
                            value={messageTemplateIndex}
                            onChange={(event) => setMessageTemplateIndex(event.target.value)}
                        >
                            {MessagesTemplate.AppointmentAskForConfirmation.map((_, index) => (
                                <MenuItem key={index} value={index}>
                                    {sentMessages.includes(index) &&  <Check color='primary' />}
                                    {index + 1}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <CustomButtom style={"secondary"} variant="outlined" icon={<IconWhatsapp color='primary' />} onClick={OnAskForAppointmentConfirmClickHandler} />
                    <CustomButtom style={"primary"} variant="outlined" icon={<FontAwesomeIcon icon={faPhone} style={{ color: ColorPalette.red }} />} onClick={OnPhoneClickHandler} />
                </div>
            );
        }
    }

    const getStatusSelector = () => {

        return (
            <FormControl fullWidth>
                <Select
                    value={state}
                    onChange={OnItemSelectHandler}
                    style={{ ...GetStyle() }}
                >
                    {AppointmensState.ChangeableBySystem.map((option, index) => {
                        let customStyle = AppointmensState.GetDataFromId(option);
                        return (
                            <MenuItem key={index} value={option} style={{ backgroundColor: customStyle.backgroundColor, height: "60px" }}>
                                {customStyle.icon}  {/*customStyle.label*/}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        )
    }

    return (
        <tr key={Date + Time} >
            <td><b>{Time}</b></td>
            <td onClick={onNameClickHandler}>{Name.split(' ')[0]}</td>
            <td> {getStatusSelector()}</td>
            <td>{Phone}</td>
            <td> {GetActionState()}</td>
            <td>{Procedure} {Resource.split(' ').map(word => word[0]).join('')}</td>
        </tr>
    );
}

export default AppointmentToConfirmItem;
