import React from 'react';
import styles from './AppointmentItem.module.css';

function HourSlotDisabledItem(props) {

  const backgroundStyle = {
    background: 'repeating-linear-gradient(45deg, #f8f8f8, #f8f8f8 10px,  #f6f6f6 10px, #f6f6f6 20px )',
    cursor : 'not-allowed',
  };

  return (
    <div className={styles.mainContainer} style={backgroundStyle}></div>
  );
}

export default HourSlotDisabledItem;