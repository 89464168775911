// Front-end: Statistics.jsx

import React, { useEffect, useState, useRef } from 'react';
import styles from './styles.module.css';
import { CreatePDF } from '../../utils/Utils';
import { DataProviderInstance } from '../../api/DataProvider';
import {
  PieChart,
  BarChart,
  ChartsReferenceLine,
  BarPlot,
  ChartsXAxis,
  ChartsYAxis,
  ChartsTooltip,
  ChartsAxisHighlight,
} from '@mui/x-charts';
import CalendarConfig from '../../consts/CalendarConfig';
import PatientConfig from '../../consts/PatientConfig';
import ColorPalette from '../../colorpalette/ColorPalette';
import AreaTitle from '../../components/areaTitle/AreaTitle';
import IconAppointments from "@mui/icons-material/Schedule";
import IconFinance from "@mui/icons-material/MonetizationOnOutlined";
import IconPatients from "@mui/icons-material/PersonAddAlt1Outlined";
import IconPatientsGender from "@mui/icons-material/PeopleAltOutlined";
import IconProcedures from "@mui/icons-material/Assignment";
import IconResources from "@mui/icons-material/Work";
import IconPayments from "@mui/icons-material/Payment";
import IconAge from "@mui/icons-material/Cake";
import IconLTV from "@mui/icons-material/AttachMoney";
import IconFirstTime from "@mui/icons-material/NumbersOutlined";
import IconReason from "@mui/icons-material/HelpOutline";
import IconAlert from "@mui/icons-material/Warning";

import Loading from '../../components/loading/Loading';
import { FormControlLabel, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, AlertTitle } from "@mui/material";
import DateRangeHeader from '../../components/dateRangeHeader/DateRangeHeader';
import TabBar from '../../components/tab-bar/TabBar';
import CustomButton from '../../components/buttons/CustomButton';
function Statistics() {

  const colorPalette = [ColorPalette.main, ColorPalette.secondary, ColorPalette.greenLight, ColorPalette.mainLight1, ColorPalette.secondaryLight, ColorPalette.mainDark, ColorPalette.mainLight1];

  let areas = [
    { id: 0, label: "Agendamentos" },
    { id: 1, label: "Pacientes" },
    { id: 2, label: "Financeiro" }
  ];


  const {
    CompanyConfig,
    GetStatistics,
  } = DataProviderInstance()


  // Inicializa startDate e endDate com datas válidas
  const [needsRefresh, setNeedsRefresh] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [dashboardData, setDashboardData] = useState(null);
  const pdfContentRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showUnknownOrigin, setShowUnknownOrigin] = useState(false);

  const [currentArea, setCurrentArea] = useState(areas[0].id);

  const appointmentsRef = useRef(null);
  const patientsRef = useRef(null);
  const financialRef = useRef(null);

  function handleAreaChange(newValue) {
    setCurrentArea(newValue);
    scrollToSection(newValue);
  };

  function GenerateReport() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    const fileName = `Relatorio_${formattedDate}.pdf`;

    CreatePDF(pdfContentRef.current, fileName);
  }

  const handleDateChange = (initialDate, finalDate) => {
    setStartDate(initialDate);
    setEndDate(finalDate);
    setNeedsRefresh(true);
  }

  const handleExport = () => {
    GenerateReport();
  }

  const scrollToSection = (areaId) => {
    switch (areaId) {
      case 0:
        appointmentsRef.current.scrollIntoView();
        break;
      case 1:
        patientsRef.current.scrollIntoView();
        break;
      case 2:
        financialRef.current.scrollIntoView();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    GetData();
  }, [needsRefresh]);

  function GetData() {
    GetStatistics(startDate, endDate, (result) => {
      setDashboardData(result);
      setIsLoaded(true);
      setNeedsRefresh(false);
    });
  };

  function GetFinancialSummary() {

    if (!dashboardData || !dashboardData.FinancialSummary || dashboardData.FinancialSummary.length == 0) {
      return (
        <div className={styles.boxRoundedContainer}>
          <AreaTitle
            Title={"Resumo financeiro"}
            Description={"Sua previsão de faturamento e o que já foi realizado"}
            Icon={<IconFinance />}
          />
          <div>Dados financeiros indisponíveis.</div>
        </div>
      );
    }

    let source = dashboardData.FinancialSummary;

    const revenuesActual = [];
    const revenuesTotal = [];

    const labels = [];

    if (source && source.length > 0) {
      source.forEach(item => {
        item.months.forEach(monthItem => {

          const total = monthItem.totalRevenue || 0;
          const actual = monthItem.actualRevenue || 0;

          revenuesActual.push(actual);
          revenuesTotal.push(total-actual);
          labels.push(CalendarConfig.Months[monthItem.month - 1].substring(0, 3) + "-" + item.year);
        });
      });
    }
    else {
      revenuesActual.push(0);
      revenuesTotal.push(0);
      labels.push("-----");
    }

    return (
      <div className={styles.boxRoundedContainer}>
        <AreaTitle
          Title={"Resumo financeiro"}
          Description={"Sua previsão de faturamento e o que já foi realizado"}
          Icon={<IconFinance />}
        />
        <BarChart
          xAxis={[{ scaleType: 'band', data: labels }]}
          series={[
            {
              data: revenuesActual,
              label: 'Realizado',
              type: 'bar',
              color: ColorPalette.main,
              stack: 'total',
            },
            {
              data: revenuesTotal,
              label: 'Previsto',
              type: 'bar',
              color: ColorPalette.mainLight1,
              stack: 'total',
            },
          ]}
          slotProps={{
            legend: {
              direction: 'row',
              position: { vertical: 'top', horizontal: 'middle' },
              itemMarkWidth: 10,
              itemMarkHeight: 10,
              markGap: 5,
              itemGap: 10,
              labelStyle: {
                fontSize: 12,
              },
            },
          }}
        >
          <BarPlot />
          <ChartsReferenceLine y={CompanyConfig.GoalBilling} label="Meta" lineStyle={{ stroke: ColorPalette.grayDark, strokeDasharray: "4 4" }} />
          <ChartsXAxis />
          <ChartsYAxis />
          <ChartsTooltip />
          <ChartsAxisHighlight x={"line"} y={true} />
        </BarChart>
      </div>
    )
  }

  function GetAppointmentsHistoryGraph() {

    if (!dashboardData || !dashboardData.AppointmentsHistory || dashboardData.AppointmentsHistory.length == 0) {
      return (
        <div className={styles.boxRoundedContainer}>
          <AreaTitle
            Title={"Atendimentos"}
            Description={"Realizados durante o período selecionado."}
            Icon={<IconAppointments />}
          />
          <div>Dados de atendimentos indisponíveis.</div>
        </div>
      );
    }

    let source = dashboardData.AppointmentsHistory;

    let completedValues = [];
    let scheduledValues = [];
    let missedValues = [];
    let canceledValues = [];
    let labels = [];

    if (source && source.length > 0) {
      source.forEach(item => {
        if (!item.months || item.months.length === 0) return;
        item.months.forEach(monthItem => {
          completedValues.push(monthItem.completed || 0);
          missedValues.push(monthItem.missed || 0);
          scheduledValues.push(monthItem.scheduled || 0);
          canceledValues.push(monthItem.canceled_by_patient || 0);
          labels.push(CalendarConfig.Months[monthItem.month - 1].substring(0, 3) + "-" + item.year);
        });
      });
    }
    else {
      completedValues.push(0);
      missedValues.push(0);
      scheduledValues.push(0);
      canceledValues.push(0);
      labels.push("-----");
    }

    return (
      <div className={styles.boxRoundedContainer}>
        <AreaTitle
          Title={"Atendimentos"}
          Description={"Realizados durante o período selecionado."}
          Icon={<IconAppointments />}
        />
        <BarChart
          xAxis={[{ scaleType: 'band', data: labels }]}
          series={[
            {
              data: completedValues,
              label: 'Realizados',
              type: 'bar',
              color: ColorPalette.main,
              stack: 'total',
            },
            {
              data: scheduledValues,
              label: 'Agendados',
              type: 'bar',
              color: ColorPalette.mainLight1,
              stack: 'total',
            },
            {
              data: canceledValues,
              label: 'Cancelados',
              type: 'bar',
              color: ColorPalette.orange,
            },
            {
              data: missedValues,
              label: 'Faltas',
              type: 'bar',
              color: ColorPalette.red,
            },
          ]}
          slotProps={{
            legend: {
              direction: 'row',
              position: { vertical: 'top', horizontal: 'middle' },
              itemMarkWidth: 10,
              itemMarkHeight: 10,
              markGap: 5,
              itemGap: 10,
              labelStyle: {
                fontSize: 12,
              },
            },
          }}
        >
          <BarPlot />
          <ChartsReferenceLine y={Number(CompanyConfig.TargetOccupation)} label="Meta" lineStyle={{ stroke: ColorPalette.grayDark, strokeDasharray: "4 4" }} />
          <ChartsXAxis />
          <ChartsYAxis />
          <ChartsTooltip />
          <ChartsAxisHighlight x={"line"} y={"true"} />
        </BarChart>
      </div>
    );
  }

  function GetAppointmentsByPatientTypeChart() {

    if (!dashboardData || !dashboardData.AppointmentsByPatientType || dashboardData.AppointmentsByPatientType.length == 0) {
      return (
        <div className={styles.boxRoundedContainer} style={{ minHeight: "auto" }}>
          <AreaTitle
            Title={"Agendamentos: Novos x Retornantes"}
            Description={"Quantidade de agendamentos de pacientes novos e retornantes por período."}
            Icon={<IconFirstTime />}          
          />
          <div>Dados de agendamentos indisponíveis.</div>
        </div>
      );
    }

    let source = dashboardData.AppointmentsByPatientType;

    // Preparar os dados para o gráfico
    const data = source.map(item => ({
      id: item.patientType,
      value: item.count,
      label: item.patientType,
    }));

    // Definir as cores para cada tipo
    const palette = [ColorPalette.greenLight, ColorPalette.main];

    return (
      <div className={styles.boxRoundedContainer}>
        <AreaTitle
          Title={<><IconAlert color="error"/> Agendamentos: Novos vs Retornante </>}
          Description={"Quantidade de agendamentos de pacientes novos e retornantes por período."}
          Icon={<IconAppointments />}
        />
     
        <PieChart
          colors={palette}
          series={[
            {
              data,
              highlightScope: { faded: 'global', highlighted: 'item' },
              faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
            },
          ]}
          slotProps={{
            legend: {
              direction: 'row',
              position: { vertical: 'middle', horizontal: 'right' },
              itemMarkWidth: 10,
              itemMarkHeight: 10,
              labelStyle: {
                fontSize: 12,
              },
            },
          }}
        />
      </div>
    );
  }

  function GetPatientsGenderGraph() {

    if (!dashboardData || !dashboardData.PatientsHistory || !dashboardData.PatientsHistory.genders || dashboardData.PatientsHistory.genders.length == 0) {
      return (
        <div className={styles.boxRoundedContainer} style={{ minHeight: "auto" }}>
          <AreaTitle
            Title={"Pacientes"}
            Description={"Categorizados por gênero"}
            Icon={<IconPatientsGender />}
          />
          <div>Dados de gênero dos pacientes indisponíveis.</div>
        </div>
      );
    }

    const palette = [ColorPalette.main, ColorPalette.secondary];

    let source = dashboardData.PatientsHistory.genders;
    let data = [];

    source.forEach((item) => {
      if (!item.gender || typeof item.count === 'undefined') return;
      let genderLabel = PatientConfig.GetGenderById(item.gender)?.Label || 'Desconhecido';
      data.push({
        id: item.gender.toString(),
        value: item.count,
        label: genderLabel,
      });
    });

    return (
      <div className={styles.boxRoundedContainer}>
        <AreaTitle
          Title={"Pacientes"}
          Description={"Categorizados por gênero"}
          Icon={<IconPatientsGender />}
        />
        <PieChart
          colors={palette}
          series={[
            {
              data,
              highlightScope: { faded: 'global', highlighted: 'item' },
              faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
            },
          ]}
          slotProps={{
            legend: {
              direction: 'column',
              position: { vertical: 'middle', horizontal: 'right' },
              itemMarkWidth: 10,
              itemMarkHeight: 10,
              labelStyle: {
                fontSize: 12,
              },
            },
          }}
        />
      </div>
    )

  }


  function GetPatientsCreated() {

    // Verificações iniciais para garantir que os dados existem
    if (
      !dashboardData ||
      !dashboardData.PatientsHistory ||
      !dashboardData.PatientsHistory.created ||
      !CompanyConfig ||
      !CompanyConfig.PatientOrigins ||
      dashboardData.PatientsHistory.created.length == 0
    ) {

      return (
        <div className={styles.boxRoundedContainer} style={{ minHeight: "auto" }}>
          <AreaTitle
            Title={"Novos pacientes"}
            Description={"Categorizados por origem de cadastro."}
            Icon={<IconPatients />}
          />
          <div>Dados de criação de pacientes indisponíveis.</div>
        </div>
      );
    }

    let source = dashboardData.PatientsHistory.created;
    let labelsSet = new Set();
    let series = [];
    let totalPatients = 0;

    // Definir uma paleta de cores
    let customColorPalette = ['#D3D3D3', ...colorPalette,]; // Adiciona uma cor cinza para origens desconhecidas

    // Mapeia as origens conhecidas para séries, garantindo ids únicos
    if (source.length > 0 && CompanyConfig.PatientOrigins.length > 0) {

      totalPatients = source.reduce((acc, item) => {
        return acc + item.origins.reduce((acc, origin) => {
          return acc + parseInt(origin.count, 10);
        }
          , 0);
      }
        , 0);
        
      series = CompanyConfig.PatientOrigins
        .filter(origin => origin.Id !== undefined && origin.Id !== null)
        .map((origin, index) => {
          const originLabel = origin.Label || 'Desconhecido';
          return {
            data: [],
            id: origin.Id.toString(),
            label: originLabel,
          };
        });
    }

    // Variável para acumular pacientes com origem desconhecida
    let unknownCount = 0;

    source.forEach(item => {
      if (!item.year || !item.month || !item.origins) return; // Pula itens inválidos
      let labelDate = `${CalendarConfig.Months[item.month - 1].substring(0, 3)}-${item.year}`;
      labelsSet.add(labelDate);

      item.origins.forEach((origin) => {
        if (!origin || typeof origin.id === 'undefined' || typeof origin.count === 'undefined') return; // Pula origens inválidas

        if (!showUnknownOrigin) {
          if (origin.id == "-1" || origin.id == "0") return;
        }

        let originId = origin.id === "-1" ? "unknown" : origin.id.toString();

        let serie = series.find(s => s.id === originId);
        if (serie) {
          serie.data.push(parseInt(origin.count, 10));
        } else {
          // Se a origem não for encontrada, acumula na variável unknownCount
          unknownCount += parseInt(origin.count, 10);
        }
      });

      // Garantir que cada série tenha um valor para o mês atual
      series.forEach(serie => {
        if (serie.data.length < labelsSet.size) {
          serie.data.push(0);
        }
      });
    });

    // Adicionar a série 'unknown-patientsCreated' se houver pacientes desconhecidos
    if (unknownCount > 0) {
      let unknownSeries = series.find(s => s.id === 'unknown');
      if (unknownSeries) {
        unknownSeries.data.push(unknownCount);
      } else {
        series.push({
          data: [unknownCount],
          id: 'unknown',
          label: 'Desconhecido',
        });
      }
      labelsSet.add('Unknown'); // Adiciona um label para a série desconhecida
    }

    let labels = Array.from(labelsSet);

    // Preencher dados faltantes com zeros
    series.forEach(serie => {
      while (serie.data.length < labels.length) {
        serie.data.push(0);
      }
    });

    // Definir as cores para cada série
    const seriesColors = series.map((serie, index) => {
      if (serie.label == 'unknown' || serie.label == 'Desconhecido') {
        return '#D3D3D3'; // Cor cinza clara para origens desconhecidas
      }
      return customColorPalette[index % customColorPalette.length];
    });

    let axis = [{ scaleType: 'band', data: labels }];

    return (
      <div className={styles.boxRoundedContainer}>
        <AreaTitle
          Title={"Novos pacientes"}
          Description={"Categorizados por origem de cadastro."}
          Icon={<IconPatients />}
        />
        <BarChart
          xAxis={axis}
          series={series}
          colors={seriesColors}
          slotProps={{
            legend: {
              direction: 'row',
              position: { vertical: 'top', horizontal: 'middle' },
              itemMarkWidth: 10,
              itemMarkHeight: 10,
              markGap: 5,
              itemGap: 10,
              labelStyle: {
                fontSize: 12,
              },
            },
          }}
        >
          <BarPlot />
          <ChartsXAxis />
          <ChartsYAxis />
          <ChartsTooltip />
        </BarChart>
        <FormControlLabel
          control={
            <Switch
              checked={showUnknownOrigin}
              onChange={() => setShowUnknownOrigin(!showUnknownOrigin)}
            />
          }
          label="Exibir origens desconhecidas"
        />
      </div>
    );
  }


  function GetProceduresChart() {

    if (!dashboardData || !dashboardData.AppointmentsByProcedure || dashboardData.AppointmentsByProcedure.length == 0) {
      return (
        <div className={styles.boxRoundedContainer} style={{ minHeight: "auto" }}>
          <AreaTitle
            Title={"Agendamentos por Procedimento"}
            Description={"Distribuição dos agendamentos por tipo de procedimento."}
            Icon={<IconProcedures />}
          />
          <div>Dados de procedimentos indisponíveis.</div>
        </div>
      );
    }

    let source = dashboardData.AppointmentsByProcedure;
    let data = source.map(item => ({
      id: item.procedure,
      value: item.count,
      label: item.procedure,
    }));

    return (
      <div className={styles.boxRoundedContainer}>
        <AreaTitle
          Title={"Agendamentos por Procedimento"}
          Description={"Distribuição dos agendamentos por tipo de procedimento."}
          Icon={<IconProcedures />}
        />
        <PieChart
          colors={colorPalette}
          series={[
            {
              data,
              highlightScope: { faded: 'global', highlighted: 'item' },
              faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
            },
          ]}
          slotProps={{
            legend: {
              direction: 'column',
              position: { vertical: 'middle', horizontal: 'right' },
              itemMarkWidth: 10,
              itemMarkHeight: 10,
              labelStyle: {
                fontSize: 12,
              },
            },
          }}
        />
      </div>
    );
  }

  function GetResourcesChart() {

    if (!dashboardData || !dashboardData.AppointmentsByResource || dashboardData.AppointmentsByResource.length == 0) {
      return (
        <div className={styles.boxRoundedContainer} style={{ minHeight: "auto" }}>
          <AreaTitle
            Title={"Agendamentos por Profissional"}
            Description={"Distribuição dos agendamentos por tipo de profissional."}
            Icon={<IconResources />}
          />
          <div>Dados de recursos indisponíveis.</div>
        </div>
      );
    }

    let source = dashboardData.AppointmentsByResource;
    let data = source.map(item => ({
      id: item.resource,
      value: item.count,
      label: item.resource,
    }));

    return (
      <div className={styles.boxRoundedContainer}>
        <AreaTitle
          Title={"Agendamentos por Profissional"}
          Description={"Distribuição dos agendamentos por tipo de profissional."}
          Icon={<IconResources />}
        />
        <PieChart
          colors={colorPalette}
          series={[
            {
              data,
              highlightScope: { faded: 'global', highlighted: 'item' },
              faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
            },
          ]}
          slotProps={{
            legend: {
              direction: 'column',
              position: { vertical: 'middle', horizontal: 'right' },
              itemMarkWidth: 10,
              itemMarkHeight: 10,
              labelStyle: {
                fontSize: 12,
              },
            },
          }}
        />
      </div>
    );
  }

  function GetTopScheduledTimesTable() {

    if (!dashboardData || !dashboardData.TopScheduledTimes || dashboardData.TopScheduledTimes.length == 0) {
      return (
        <div className={styles.boxRoundedContainer} style={{ minHeight: "auto" }}>
          <AreaTitle
            Title={"Horários Mais Agendados"}
            Description={"Os horários com maior número de agendamentos."}
            Icon={<IconAppointments />}
          />
          <div>Dados de horários indisponíveis.</div>
        </div>
      );
    }

    let source = dashboardData.TopScheduledTimes;

    // Certifique-se de que source é um array
    if (!Array.isArray(source)) source = [];

    // Sort the source in descending order by count
    source.sort((a, b) => (b.count || 0) - (a.count || 0));

    return (
      <div className={styles.boxRoundedContainer}>
        <AreaTitle
          Title={"Horários Mais Agendados"}
          Description={"Os horários com maior número de agendamentos."}
          Icon={<IconAppointments />}
        />
        <TableContainer component={Paper}>
          <Table aria-label="top scheduled times">
            <TableHead>
              <TableRow>
                <TableCell><strong>Horário</strong></TableCell>
                <TableCell align="right"><strong>Quantidade</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {source.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {row.time || '-----'}
                  </TableCell>
                  <TableCell align="right">{row.count !== undefined ? row.count : 0}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }

  function GetPaymentMethodsChart() {

    if (!dashboardData || !dashboardData.PaymentMethods || dashboardData.PaymentMethods.length == 0) {
      return (
        <div className={styles.boxRoundedContainer} style={{ minHeight: "auto" }}>
          <AreaTitle
            Title={"Valores por Método de Pagamento"}
            Description={"Distribuição dos valores recebidos por cada método de pagamento."}
            Icon={<IconPayments />}
          />
          <div>Dados de métodos de pagamento indisponíveis.</div>
        </div>
      );
    }

    let source = dashboardData.PaymentMethods;
    let data = source.map(item => ({
      id: item.paymentMethod,
      value: item.amount,
      label: item.paymentMethod,
    }));

    return (
      <div className={styles.boxRoundedContainer}>
        <AreaTitle
          Title={"Valores por Método de Pagamento"}
          Description={"Distribuição dos valores recebidos por cada método de pagamento."}
          Icon={<IconPayments />}
        />
        <PieChart
          colors={colorPalette}
          series={[
            {
              data,
              highlightScope: { faded: 'global', highlighted: 'item' },
              faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
            },
          ]}
          slotProps={{
            legend: {
              direction: 'column',
              position: { vertical: 'middle', horizontal: 'right' },
              itemMarkWidth: 10,
              itemMarkHeight: 10,
              labelStyle: {
                fontSize: 12,
              },
            },
          }}
        />
      </div>
    );
  }

  function GetPatientsRetationRate() {

    if (!dashboardData || typeof dashboardData.PatientsRetentionRate === 'undefined') {
      return (
        <div className={styles.boxRoundedContainer} style={{ minHeight: "auto" }}>
          <AreaTitle
            Title={"Taxa de Retenção de Pacientes"}
            Description={"Percentual de pacientes que retornaram ao consultório."}
            Icon={<IconPatients />}
          />
          <div>Dados de taxa de retenção de pacientes indisponíveis.</div>
        </div>
      );
    }

    let retentionRate = dashboardData.PatientsRetentionRate;

    // Prepare data for the pie chart
    const data = [
      { id: 'Retidos', value: retentionRate, label: 'Retidos' },
      { id: 'Não Retidos', value: 100 - retentionRate, label: 'Não Retidos' },
    ];

    const palette = [ColorPalette.main, ColorPalette.secondary];


    return (
      <div className={styles.boxRoundedContainer}>
        <AreaTitle
    Title={<><IconAlert color="error"/> Taxa de Retenção de Pacientes</>}
    Description={"Percentual de pacientes que retornaram ao consultório."}
          Icon={<IconPatients />}
        />
        <PieChart
          colors={palette}
          series={[
            {
              data,
              highlightScope: { faded: 'global', highlighted: 'item' },
              faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
            },
          ]}
          slotProps={{
            legend: {
              direction: 'column',
              position: { vertical: 'middle', horizontal: 'right' },
              itemMarkWidth: 10,
              itemMarkHeight: 10,
              labelStyle: {
                fontSize: 12,
              },
            },
          }}
        />
      </div>
    );
  }

  function GetAverageLTV() {

    if (!dashboardData || typeof dashboardData.AverageLTV === 'undefined'  || typeof dashboardData.AverageLTV === null) {
      return (
        <div className={styles.boxRoundedContainer} style={{ minHeight: "auto" }}>
          <AreaTitle
            Title={"LTV Médio dos Pacientes"}
            Description={"Valor médio de vida de um paciente."}
            Icon={<IconLTV />}
          />
          <div>Dados de LTV indisponíveis.</div>
        </div>
      );
    }

    const ltv = Number(dashboardData.AverageLTV);

    return (
      <div className={styles.boxRoundedContainer} style={{ minHeight: "auto" }}>
        <AreaTitle
          Title={`LTV Médio dos Pacientes:  R$ ${ltv.toFixed(2)}`}
          Description={"Valor médio de vida de um paciente."}
          Icon={<IconLTV />}
        />
      </div>
    );
  }

  function GetAgeDistributionChart() {

    if (!dashboardData || !dashboardData.AgeDistribution || dashboardData.AgeDistribution.length == 0) {
      return (
        <div className={styles.boxRoundedContainer}>
          <AreaTitle
            Title={"Faixa de Idade dos Pacientes"}
            Description={"Distribuição dos pacientes por faixa de idade."}
            Icon={<IconAge />}
          />
          <div>Dados de faixa de idade indisponíveis.</div>
        </div>
      );
    }

    let source = dashboardData.AgeDistribution;
    let data = source.map(item => ({
      id: item.ageGroup,
      value: item.count,
      label: item.ageGroup,
    }));

    return (
      <div className={styles.boxRoundedContainer}>
        <AreaTitle
          Title={"Faixa de Idade dos Pacientes"}
          Description={"Distribuição dos pacientes por faixa de idade."}
          Icon={<IconAge />}
        />
        <BarChart
          xAxis={[{ scaleType: 'band', data: source.map(item => item.ageGroup) }]}
          series={[
            {
              data: source.map(item => item.count),
              label: 'Quantidade',
              type: 'bar',
              color: ColorPalette.main,
            },
          ]}
          slotProps={{
            legend: {
              display: false,
            },
          }}
        >
          <BarPlot />
          <ChartsXAxis />
          <ChartsYAxis />
          <ChartsTooltip />
        </BarChart>
      </div>
    );
  }

  function GetPatientComplaintsTable() {

    if (!dashboardData || !dashboardData.PatientComplaints || dashboardData.PatientComplaints.length == 0) {
      return (
        <div className={styles.boxRoundedContainer}>
          <AreaTitle
            Title={"Motivos de Atendimentos"}
            Description={"Principais queixas dos pacientes."}
            Icon={<IconReason />}
          />
          <div>Dados de queixas de pacientes indisponíveis.</div>
        </div>
      );
    }

    const source = dashboardData.PatientComplaints;

    // Sort the source in descending order by count
    source.sort((a, b) => (b.count || 0) - (a.count || 0));

    return (
      <div className={styles.boxRoundedContainer}>
        <AreaTitle
          Title={"Motivos de Atendimentos"}
          Description={"Principais queixas dos pacientes."}
          Icon={<IconReason />}
        />
        <TableContainer component={Paper}>
          <Table aria-label="patient complaints">
            <TableHead>
              <TableRow>
                <TableCell><strong>Queixa</strong></TableCell>
                <TableCell align="right"><strong>Quantidade</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {source.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {row.complaint || '-----'}
                  </TableCell>
                  <TableCell align="right">{row.count !== undefined ? row.count : 0}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }

  function GetPatientLocationsTable() {

    if (!dashboardData || !dashboardData.PatientLocations || dashboardData.PatientLocations.length == 0) {
      return (
        <div className={styles.boxRoundedContainer}>
          <AreaTitle
            Title={"Agendamentos por Origem Geográfica"}
            Description={"Distribuição de onde os pacientes estão vindo, auxiliando em campanhas de marketing localizadas."}
            Icon={<IconAppointments />}
          />
          <div>Dados de localização indisponíveis.</div>
        </div>
      );
    }

    const source = dashboardData.PatientLocations;

    // Ordenar por quantidade descendente
    const sortedSource = [...source].sort((a, b) => b.count - a.count);

    return (
      <div className={styles.boxRoundedContainer}>
        <AreaTitle
          Title={"Pacientes por Origem Geográfica"}
          Description={"Distribuição de onde os pacientes estão vindo, auxiliando em campanhas de marketing localizadas."}
          Icon={<IconAppointments />}
        />
        <TableContainer component={Paper}>
          <Table aria-label="patient locations">
            <TableHead>
              <TableRow>
                <TableCell><strong>Endereço</strong></TableCell>
                <TableCell align="right"><strong>Quantidade de Pacientes</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedSource.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {row.location || '-----'}
                  </TableCell>
                  <TableCell align="right">{row.count !== undefined ? row.count : 0}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }

  return (
    isLoaded ?
      <div className='page-content' style={{ gap: "20px", display: "flex", flexDirection: "column" }}>
        <DateRangeHeader
          onChange={handleDateChange}
        />
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "20px", justifyContent: "space-between" }}>
          <div style={{ width: "100%" }}>
            <TabBar labels={areas.map(item => item.label)} value={currentArea} onChange={handleAreaChange} />
          </div>
          <CustomButton variant="contained" style={"primary"} label={"Exportar"} onClick={handleExport} />
        </div>
        <div ref={pdfContentRef} style={{ display: "flex", flexDirection: "column", gap: "20px", paddingTop: 'initial' }}>
          {/* Appointments Section */}
          <h2 ref={appointmentsRef}>Agendamentos</h2>
          <div className={styles.gridContainer}>
            {GetAppointmentsHistoryGraph()}
            {GetAppointmentsByPatientTypeChart()}
            {GetProceduresChart()}
            {GetResourcesChart()}
            {GetTopScheduledTimesTable()}
          </div>

          {/* Patients Section */}
          <h2 ref={patientsRef}>Pacientes</h2>
          <div className={styles.gridContainer}>
            {GetPatientsCreated()}
            {GetPatientsRetationRate()}
            {GetPatientsGenderGraph()}
            {GetAgeDistributionChart()}
            {GetPatientComplaintsTable()}
            {GetPatientLocationsTable()}
          </div>

          {/* Financial Section */}
          <h2 ref={financialRef}>Financeiro</h2>
          <div className={styles.gridContainer}>
            {GetFinancialSummary()}
            {GetPaymentMethodsChart()}
            {GetAverageLTV()}
          </div>

        </div>
      </div> : <div className='page-content'><Loading /></div>
  );
}

export default Statistics;
