import React, { useEffect } from "react";
import styles from './PopupBase.module.css';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
function PopupBase(props) {
    const { Title, Content, Footer, PositiveButton, NegativeButton, OnClose } = props;

    useEffect(() => {
        // Travar o scroll da página
        const body = document.querySelector('body');
        const html = document.documentElement;

        // Guardar o scroll atual para restaurá-lo ao fechar o popup
        const currentScrollY = window.scrollY;

        // Travar o scroll
        body.style.overflow = 'hidden';
        html.style.overflow = 'hidden';

        // Resetar o scroll da página para o topo
        window.scrollTo(0, 0);

        return () => {
            // Liberar o scroll da página
            body.style.overflow = 'auto';
            html.style.overflow = 'auto';

            // Restaurar o scroll original
            window.scrollTo(0, currentScrollY);
        };
    }, []);

    function OnCloseClickHandler() {
        OnClose();
    }

    return (
        <>
            <div className={styles.background}></div>
            <div className={styles.box}>
                <div className={styles.header}>
                    <h2 className={styles.title}>{Title}</h2>
                    <CloseOutlinedIcon className={styles.close} onClick={OnCloseClickHandler} fontSize="large"/>
                </div>
                <div className={styles.content}>
                    {Content}
                </div>
                <div className={styles.footer}>
                    {Footer}
                </div>
                <div className={styles.buttons}>
                    {NegativeButton}
                    {PositiveButton}
                </div>
            </div>
        </>

    );
}

export default PopupBase;
