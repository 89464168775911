import React, { useEffect } from 'react';
import styles from "./PersonalInfo.module.css";
import CalendarConfig from "../../../../consts/CalendarConfig";
import PatientConfig from "../../../../consts/PatientConfig";
import CustomButton from "../../../../components/buttons/CustomButton";
import { GetFriendlyCPF } from '../../../../utils/Utils';

const PersonalInfo = ({ data, onEdit }) => {

    function GetFriendlyDate() {
        if (data.birthday) {
            return CalendarConfig.GetFullDateName(data.birthday);
        } else {
            return "";
        }
    }

    function GetGender() {
        if (data.gender != null && data.gender != "") {
            return PatientConfig.GetGenderById(data.gender).Label;
        }
        else {
            return "";
        }
    }


    function OnEditClickHandler() {
        onEdit();
    }

    return (
        <div className={styles.mainContainer}>
            <div className={styles.patientInfosGroup}>
                <h3 className={styles.patientInfosTitle}>Informações gerais</h3>
                <div className={styles.patientInfosItem}>
                    <span className={styles.patientInfosItemKey}>Registro:</span>
                    <span className={styles.patientInfosItemValue}>{data.id}</span>
                </div>
                <div className={styles.patientInfosItem}>
                    <span className={styles.patientInfosItemKey}>Nome:</span>
                    <span className={styles.patientInfosItemValue}>{data.name}</span>
                </div>
                <div className={styles.patientInfosItem}>
                    <span className={styles.patientInfosItemKey}>CPF:</span>
                    <span className={styles.patientInfosItemValue}>{GetFriendlyCPF(data.cpf)}</span>
                </div>
                <div className={styles.patientInfosItem}>
                    <span className={styles.patientInfosItemKey}>Nascimento:</span>
                    <span className={styles.patientInfosItemValue}>{GetFriendlyDate()}</span>
                </div>
            </div>

            <div className={styles.patientInfosGroup}>
                <h3 className={styles.patientInfosTitle}>Contato e localização</h3>
                <div className={styles.patientInfosItem}>
                    <span className={styles.patientInfosItemKey}>Telefone:</span>
                    <span className={styles.patientInfosItemValue}>{data.phone}</span>
                </div>
                <div className={styles.patientInfosItem}>
                    <span className={styles.patientInfosItemKey}>E-mail:</span>
                    <span className={styles.patientInfosItemValue}>{data.email}</span>
                </div>

                <div className={styles.patientInfosItem}>
                    <span className={styles.patientInfosItemKey}>Endereço:</span>
                    <span className={styles.patientInfosItemValue}>{data.address + ' ' + data.number + ' - ' + data.district + ", " + data.city + ", " + data.state} </span>
                </div>
            </div>

            <div className={styles.containerEditButton}>
                <CustomButton label={"Editar cadastro"} variant="contained" style="primary" fullWidth={false} onClick={OnEditClickHandler} />
            </div>

        </div>
    );
};

export default PersonalInfo;