import React from "react";
import "./PatientStatus.css";
import PatientConfig from "../../../../consts/PatientConfig";
import { Patient_State_Active,Patient_State_Inactive } from "../../../../consts/PatientConfig";
function PatientStatus(props)
{
    const {Value} = props;

    let className = "patient-status";

    let statusData = PatientConfig.GetStateById(Value);

    switch(statusData.Label)
    {
        case Patient_State_Active:
            className = "patient-status-activated";
            break;
        case Patient_State_Inactive:
            className = "patient-status-disabled";
            break;
    }

    return(
         <div className={className}></div>        
    );
}

export default PatientStatus;