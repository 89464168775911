import React from 'react';
import styles from './Stats.module.css';

const Stats = ({ icon, label, value }) => {
    return (
        <div className={styles.container}>
            <div className={styles.label}>{label}</div>
            <div className={styles.containerValues}>
                <div className={styles.icon}>{icon}</div>
                <div className={styles.value}>{value}</div>
            </div>
        </div>
    );
};

export default Stats;