import React from "react";

import "./Header.css";
import { useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import IconNotification from "@mui/icons-material/NotificationsOutlined";

import { Badge, Tooltip } from "@mui/material";

import IconMenu from "@mui/icons-material/MenuRounded";

import UserDisplay from "./user/UserDisplay";
import AppConfig from '../consts/AppConfig';
import { DataProviderInstance } from "../api/DataProvider";
import ColorPalette from "../colorpalette/ColorPalette";
import RemotePaths from "../consts/RemotePaths";

function Header(props) {

  const { isMobile, OpenMenuCallback } = props;
  const { UserData, CompanyConfig } = DataProviderInstance();

  function getHeaderText(pathname) {
    // Check if the last part of the path is a number ( id )
    if (pathname.includes('/')) {
      const parts = pathname.split('/');
      const lastPart = parts[parts.length - 1];
      const isId = !isNaN(lastPart);
      if (isId) {
        pathname = pathname.replace(`/${lastPart}`, '/:id');
      }
    }

    let date = AppConfig.GetRouteLabel(pathname);

    if (date)
      return date.label;
    else
      return "";
  }

  const location = useLocation();
  const breadcrumb = CompanyConfig.Name + " / " + getHeaderText(location.pathname);
  let notif = Math.floor(Math.random() * 121);

  const breadcrumbSplited = breadcrumb.split('/');

  const GetIcon = () => {
    return <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: "10px" }} />;
  }

  function GetBreadcrumb() {
    return breadcrumb.split('/').map((item, index) => {
      return (
        <div key={index} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <h4 >{item}</h4>
          {index < breadcrumbSplited.length - 1 && <GetIcon />}
        </div>
      )
    }
    )
  }

  return (

    <header className="header">
        {isMobile && <IconMenu onClick={OpenMenuCallback} style={{ cursor: "pointer" }} />}
        <div style={{ display: "flex", alignItems: "center", gap: "10px"}}>
        {!isMobile && GetBreadcrumb()}
        </div>
        <div style={{ display: "flex", flex: "1", alignItems: "center", justifyContent: "flex-end", marginRight: "20px", gap: "40px" }}>
          {/*<Tooltip title={`Você tem ${notif} mensagens`} arrow>
            <Badge badgeContent={notif} color="error" fontSize="small" >
              <IconNotification />
            </Badge>
          </Tooltip>*/}
          <UserDisplay Picture={UserData.picture != "" ? RemotePaths.PATH_USER_UPLOADS_USER_PICTURE(UserData.id) +"/"+ UserData.picture : null} Name={UserData.name} Role={UserData.role} />
        </div>
    </header>
  );
}

export default Header;