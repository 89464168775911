import React from 'react';
import styles from './MenuItem.module.css';
import { NavLink } from 'react-router-dom';

const MenuItem = ({ label, path, icon, OnHide }) => {
    return (
        <NavLink key={path} to={path} style={{ textDecoration: 'none' }} onClick={OnHide}>
            <div className={styles.container}>
                {icon}
                <p>{label}</p>
            </div>
        </NavLink>
    );
};

export default MenuItem;