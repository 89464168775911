import React, { useEffect, useState, useRef } from 'react';
import PopupBase from "../base/PopupBase.js";
import CustomButton from "../../components/buttons/CustomButton.js";
import IconPrint from '@mui/icons-material/Print';
import styles from "./styles.module.css";
import { CreatePDF } from '../../utils/Utils.js';
import { DataProviderInstance } from "../../api/DataProvider.js";
import DocumentDefaultTemplate from "../../components/documents-templates/DocumentDefaultTemplate.js";

function ViewDocumentPopup(props) {

    const { patientData, data, OnHide } = props;
    const { CompanyConfig } = DataProviderInstance();

    const professionalData = CompanyConfig.Resources[0];// FIXME: This is a placeholder. It should be the professional who created the document.

    const pdfContentRef = useRef(null);

    function OnCloseClickHandler() {
        Hide();
    }

    function Hide() {
        OnHide();
    }

    const [isPrinting, setIsPrinting] = useState(false);

    function GetFooter() {
        return (
            <div className={styles.footer}>
                <CustomButton variant="outlined" style="secondary" label={"Cancelar"} onClick={OnCloseClickHandler} />
                <CustomButton variant="contained" style="primary" label={"Imprimir"} icon={<IconPrint />} onClick={OnPrintHandler} />
            </div>
        )
    }

    function OnPrintHandler() {

        let documentTypeName = data.title;
        let currentDate = new Date();
        let formattedDate = currentDate.getFullYear() + "_" + (currentDate.getMonth() + 1) + "_" + currentDate.getDate() + "_" + currentDate.getHours() + "_" + currentDate.getMinutes();
        let patientName = patientData.name;

        let fileName = `${patientName}_${formattedDate}_${documentTypeName}.pdf`.replace(/\s/g, '_');
        let content = pdfContentRef.current;

        CreatePDF(content, fileName);
        setIsPrinting(false);
    }

    function formatDate(date) {
        const [year, month, day] = date.split("-");
        return `${day}/${month}/${year}`;
    }

    function GetContent() {

        return (
            <div className={styles.mainContainer}>
                <div ref={pdfContentRef}>
                    <DocumentDefaultTemplate
                        date={formatDate(data.date)}
                        companyData={CompanyConfig}
                        patientData={patientData}
                        professionalData={professionalData}
                        title={data.title}
                        description={data.description}
                    />
                </div>
            </div>
        );
    }

    return <PopupBase Title={"Visualizar documento"} Content={GetContent()} Footer={GetFooter()} OnClose={OnCloseClickHandler} />
}

export default ViewDocumentPopup;