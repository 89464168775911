import React, { useState, useEffect } from 'react';
import { TextField, Button, Checkbox, FormControlLabel, Grid, Box, Typography, Select, MenuItem, InputLabel, Switch, FormControl } from '@mui/material';
import AreaTitle from '../../../../components/areaTitle/AreaTitle';
import { DataProviderInstance } from '../../../../api/DataProvider';
import styles from './GeneralConfig.module.css';
import CustomButton from '../../../../components/buttons/CustomButton';
const GeneralConfig = () => {

    const { CompanyConfig, UpdateCompanyConfig } = DataProviderInstance();

    const initialState = {
        availableDaysOfWeek: CompanyConfig.AvailableDaysOfWeek,
        availableTime: CompanyConfig.AvailableTime,
        blockedTimes: CompanyConfig.BlockedTimes,
        targetOccupation: CompanyConfig.TargetOccupation,
        timeInterval: CompanyConfig.TimeInterval,
        goalAppointments: CompanyConfig.GoalAppointments,
        goalBilling: CompanyConfig.GoalBilling,
        allowAutomaticWhatsappMessages: CompanyConfig.AllowAutomaticWhatsappMessages,
    };

    const [values, setValues] = useState(initialState);
    const [isEditable, setIsEditable] = useState(false);
    const [timeSlots, setTimeSlots] = useState([]);

    const handleChange = (field, value) => {
        setValues((prevValues) => ({
            ...prevValues,
            [field]: value,
        }));
    };

    const generateTimeSlots = (startTime, endTime, interval) => {
        const slots = [];
        for (let time = startTime; time < endTime; time += interval) {
            slots.push(time);
        }
        return slots;
    };

    useEffect(() => {
        const [start, end] = values.availableTime;
        if (start < end) {
            setTimeSlots(generateTimeSlots(start, end, values.timeInterval));
        }
    }, [values.availableTime, values.timeInterval]);

    const handleBlockedTimesChange = (hour) => {
        if (values.blockedTimes.includes(hour)) {
            handleChange('blockedTimes', values.blockedTimes.filter(time => time !== hour));
        } else {
            handleChange('blockedTimes', [...values.blockedTimes, hour]);
        }
    };

    const handleEdit = () => {
        setIsEditable(true);
    };

    const handleSave = () => {
        if (values.availableTime[0] >= values.availableTime[1]) {
            alert('Horário de início não pode ser maior ou igual ao horário de término.');
            return;
        }

        console.log('Valores Salvos:', values);
        UpdateCompanyConfig(values, (result) => {
            console.log('Resultado:', result);
        });

        setIsEditable(false);
    };

    const handleReset = () => {
        setValues(initialState);
        setIsEditable(false);
    };

    return (
        <div className={styles.mainContainer}>
            <div className={styles.titleContainer}>
                <AreaTitle Title="Configurações Gerais" />
                    {isEditable ? (
                        <>
                            <CustomButton variant="contained" label="Salvar alterações" style="primary" onClick={handleSave} />
                            <CustomButton variant="outlined" label=" Limpar Alterações" style="primary" onClick={handleReset} />
                        </>
                    ) : (
                        <CustomButton variant="contained" label="Editar" style="primary" onClick={handleEdit} />
                    )}
            </div>
            <div className={styles.areaContainer} >
                <AreaTitle Title="Funcionamento" />
                <h2></h2>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            label="Horário de Início"
                            type="number"
                            value={values.availableTime[0]}
                            onChange={(e) => handleChange('availableTime', [Number(e.target.value), values.availableTime[1]])}
                            disabled={!isEditable}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Horário de Término"
                            type="number"
                            value={values.availableTime[1]}
                            onChange={(e) => handleChange('availableTime', [values.availableTime[0], Number(e.target.value)])}
                            disabled={!isEditable}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>

                </Grid>

                <Grid container spacing={0}>
                    {timeSlots.map((hour) => (
                        <Grid item xs={0} key={hour}>
                            <FormControlLabel
                                control={<Checkbox checked={values.blockedTimes.includes(hour)} />}
                                label={`${hour}:00`}
                                disabled={!isEditable}
                                onChange={() => handleBlockedTimesChange(hour)}
                            />
                        </Grid>
                    ))}
                </Grid>
            </div>

            <div className={styles.areaContainer} >
                <AreaTitle Title="Agenda" />
                <div style={{ display: 'flex', alignItems: 'center', gap: "10px" }}>
                    <FormControl fullWidth>
                        <InputLabel>Intervalo de Tempo</InputLabel>
                        <Select
                            value={values.timeInterval}
                            onChange={(e) => handleChange('timeInterval', Number(e.target.value))}
                            disabled={!isEditable}
                        >
                            <MenuItem value={1}>1 hora</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        label="Máximo de Agendamentos por Dia"
                        type="number"
                        value={values.goalAppointments}
                        onChange={(e) => handleChange('goalAppointments', e.target.value)}
                        disabled={!isEditable}
                        fullWidth
                    />
                </div>
            </div>

            <div className={styles.areaContainer} >
                <AreaTitle Title="Metas" />
                <div style={{ display: 'flex', alignItems: 'center', gap: "10px" }}>
                    <TextField
                        label="Meta de agendamentos por mês"
                        type="number"
                        value={values.targetOccupation}
                        onChange={(e) => handleChange('targetOccupation', e.target.value)}
                        disabled={!isEditable}
                        fullWidth
                    />
                    <TextField
                        label="Meta de Faturamento Mensal"
                        type="number"
                        value={values.goalBilling}
                        onChange={(e) => handleChange('goalBilling', e.target.value)}
                        disabled={!isEditable}
                        fullWidth
                    />

                </div>
            </div>
            <div className={styles.areaContainer} >
                <AreaTitle Title="Automação" />
                <FormControlLabel
                    control={
                        <Switch
                            checked={values.allowAutomaticWhatsappMessages == 1 ? true : false}
                            onChange={(e) => handleChange('allowAutomaticWhatsappMessages', e.target.checked ? 1 : 0)}
                            disabled={!isEditable}
                        />
                    }
                    label="Permitir Mensagens Automáticas no WhatsApp"
                />
            </div>
        </div>
    );
};

export default GeneralConfig;
