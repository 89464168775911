import React, { useEffect, useState } from 'react';
import styles from './WaitingList.module.css';
import Loading from '../../components/loading/Loading';
import AreaTitle from '../../components/areaTitle/AreaTitle';
import { DataProviderInstance } from "../../api/DataProvider";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit'; // Import do ícone de edição
import CustomButton from '../../components/buttons/CustomButton';
import AddWaitingListPopup from '../../popup/AddWaitingListPopup/AddWaitingListPopup';
import MessagesTemplate from '../../consts/MessagesTemplate';
import { SendWhatsAppMessage } from '../../api/WhatsappAPI';
import CalendarConfig from '../../consts/CalendarConfig';
import ColorPalette from '../../colorpalette/ColorPalette';
import ConfirmPopup from '../../popup/ConfirmPopup/ConfirmPopup';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Alert } from '@mui/material';

function WaitingList() {

  const { GetPatientById, GetWaitingList, DeleteWaitingList } = DataProviderInstance();
  const [data, setData] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  const [visibilityAddWaitingListPopup, setVisibilityAddWaitingListPopup] = useState(false);
  const [visibilityConfirmPopup, setVisibilityConfirmPopup] = useState(false);
  const [currentDateIndex, setCurrentDateIndex] = useState({}); // Índice atual da data sugerida para cada paciente
  const [error, setError] = useState(null); // Estado para erros
  const [selectedItem, setSelectedItem] = useState(null); // Paciente selecionado para deleção

  useEffect(() => {
    LoadData();
  }, []);

  useEffect(() => {
    if (refreshData) {
      LoadData();
    }
  }, [refreshData]);

  // Função para carregar a lista de espera
  function LoadData() {
    GetWaitingList((response) => {
      if (response.error) {
        setError("Erro ao carregar a lista de espera.");
        setData([]);
      } else {
        setData(response.waitingList);
        setError(null);
      }
      setRefreshData(false);
    });
  }

  // Funções para alternar entre as datas sugeridas
  function getNextDateIndex(patientId, length) {
    const currentIndex = currentDateIndex[patientId] || 0;
    return (currentIndex + 1) % length;
  }

  function getPreviousDateIndex(patientId, length) {
    const currentIndex = currentDateIndex[patientId] || 0;
    return (currentIndex - 1 + length) % length;
  }

  // Função para enviar mensagem via WhatsApp
  function OnContactClickHandler(item) {
    let message = MessagesTemplate.SuggestDateFromWaitingList;
    let patient = GetPatientById(item.patientId + "");

    if (!patient) {
      console.error(`Paciente com ID ${item.patientId} não encontrado.`);
      return;
    }

    const dateIndex = currentDateIndex[item.patientId] || 0;
    const availableDates = item.availableDates || [];

    if (availableDates.length === 0) {
      console.warn(`Nenhuma data disponível para o paciente ${patient.name}.`);
      return;
    }

    let date = new Date(availableDates[dateIndex]);
    if (isNaN(date.getTime())) {
      console.error(`Data inválida: ${availableDates[dateIndex]}`);
      return;
    }

    let day = date.getDate().toString().padStart(2, '0');
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let when = date.toLocaleDateString('pt-BR', { weekday: 'long' });
    let time = date.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' });

    message = message.replace("{name}", patient.name.split(' ')[0]);
    message = message.replace("{day}", day);
    message = message.replace("{month}", CalendarConfig.Months[parseInt(month) - 1]);
    message = message.replace("{when}", when);
    message = message.replace("{time}", time);

    SendWhatsAppMessage(patient.phone, MessagesTemplate.ConvertToWhatsAppText(message));
  }

  // Função para lidar com o clique no botão de deletar
  function OnDeleteClickHandler(item) {
    setSelectedItem(item);
    setVisibilityConfirmPopup(true);
  }

  // Função para confirmar a deleção
  function OnDeleteConfirm() {
    if (!selectedItem) return;
    DeleteWaitingList(selectedItem.id, () => {
      setSelectedItem(null);
      setRefreshData(true);
    });
    setVisibilityConfirmPopup(false);
  }

  // Função para cancelar a deleção
  function OnDeleteCancel() {
    setSelectedItem(null);
    setVisibilityConfirmPopup(false);
  }

  // Função para fechar o popup de adicionar lista de espera
  function OnPopupClose() {
    setVisibilityAddWaitingListPopup(false);
    setSelectedItem(null); // Resetar o paciente selecionado para edição
  }

  // Função chamada quando um novo item é criado
  function OnItemCreated() {
    setRefreshData(true);
  }


  // Função para abrir o popup de edição
  function handleEditButtonClick(item) {
    setSelectedItem(item);
    setVisibilityAddWaitingListPopup(true);
  }

  // Função para formatar a data
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "Data inválida";
    }
    const formattedDate = date.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' });
    const weekday = date.toLocaleDateString('pt-BR', { weekday: 'long' });
    const time = date.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' });
    return `${weekday}, ${formattedDate} às ${time}`;
  };

  // Função para obter a label da prioridade
  const getPriorityLabel = (priority) => {
    switch (priority) {
      case 1:
        return 'Alta';
      case 2:
        return 'Média';
      case 3:
        return 'Baixa';
      default:
        return 'Nenhuma';
    }
  };

  // Função para obter a cor da prioridade
  const getPriorityColor = (priority) => {
    switch (priority) {
      case 1:
        return ColorPalette.red;
      case 2:
        return ColorPalette.orange;
      case 3:
        return ColorPalette.mainLight1;
      default:
        return ColorPalette.gray;
    }
  };

  // Filtrar os dados antes de mapear (adapte conforme necessário)
  const filteredData = data ? data.filter(item => {
    // Exemplo: você pode adicionar filtros adicionais aqui se necessário
    return true;
  }) : [];

  if (data === null) {
    return <Loading />;
  }

  return (
    <div className='page-content'>
      {/* Exibir mensagem de erro, se houver */}
      {error && <Alert severity="error">{error}</Alert>}

      {/* Popup para adicionar à lista de espera */}
      {visibilityAddWaitingListPopup && (
        <AddWaitingListPopup
          OnHide={OnPopupClose}
          OnCreated={OnItemCreated}
          initialData={selectedItem ? selectedItem : null} // Passar dados iniciais se for edição
        />
      )}

      {/* Popup de confirmação para deletar */}
      {visibilityConfirmPopup && selectedItem && (
        <ConfirmPopup
          Visibility={visibilityConfirmPopup}
          Title={"Remover paciente"}
          Description={`Você irá remover o paciente ${GetPatientById(selectedItem.patientId)?.name || "Desconhecido"} da sua lista de espera.`}
          OnHide={OnDeleteCancel}
          OnConfirm={OnDeleteConfirm}
          OnCancel={OnDeleteCancel}
        />
      )}


      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        {/* Título e botão de adicionar */}
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "20px" }}>
          <AreaTitle Title={"Lista de espera"} Description={"Pacientes que estão no aguardo de uma disponibilidade."} />
          <CustomButton
            variant="contained"
            style="primary"
            label={"Adicionar"}
            onClick={() => setVisibilityAddWaitingListPopup(true)}
          />
        </div>

        {/* Tabela da lista de espera */}
        <div className={styles.containerTable}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Paciente</th>
                <th>Prioridade</th>
                <th>Tempo na fila</th>
                <th>Data Sugerida</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredData.length === 0 ? (
                <tr>
                  <td colSpan="4" style={{ textAlign: 'center' }}>Nenhum paciente na lista de espera.</td>
                </tr>
              ) : (
                filteredData.map((item) => {
                  const dateIndex = currentDateIndex[item.patientId] || 0;
                  const suggestedDate = item.availableDates && item.availableDates.length > 0
                    ? item.availableDates[dateIndex]
                    : null;

                    return (
                    <tr key={item.id}>
                      <td>{GetPatientById(item.patientId)?.name || "Paciente Desconhecido"}</td>
                      <td style={{ color: getPriorityColor(item.priority), display: 'flex', alignItems: 'center' }}>
                      {getPriorityLabel(item.priority)}
                      </td>
                      <td> {item.daysInQueue === 0
                      ? "Menos de 24 horas"
                      : item.daysInQueue === 1
                      ? "1 dia"
                      : `${item.daysInQueue} dias`}
                      </td>
                      <td>
                        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        {item.availableDates.length > 1 ? 
                        <NavigateBeforeIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => setCurrentDateIndex(prev => ({
                          ...prev,
                          [item.patientId]: getPreviousDateIndex(item.patientId, item.availableDates.length)
                          }))}
                        />
                         : null}
                         {suggestedDate ?  <span>{formatDate(suggestedDate)}</span> : null} 
                        {item.availableDates.length > 1 ? 
                        <NavigateNextIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => setCurrentDateIndex(prev => ({
                          ...prev,
                          [item.patientId]: getNextDateIndex(item.patientId, item.availableDates.length)
                          }))}
                        />
                        : null}
                        </div>
                      </td>
                      <td>
                      <div style={{ display: "flex", flexDirection: "row", gap: "15px", justifyContent: "center" }}>
                        {suggestedDate ? 
                          <WhatsAppIcon
                          style={{ cursor: "pointer", color: ColorPalette.whatsappGreen }}
                          onClick={() => OnContactClickHandler(item)}
                          title="Enviar WhatsApp"
                          />
                          : 
                          <WhatsAppIcon
                          style={{ color: ColorPalette.grayLight }}
                          onClick={() => OnContactClickHandler(item)}
                          title="Enviar WhatsApp"
                          enabled={false}
                        />
                        }

                        <EditIcon
                        style={{ cursor: "pointer"}}
                        onClick={() => handleEditButtonClick(item)}
                        title="Editar"
                        />
                        <DeleteIcon
                        style={{ cursor: "pointer", color: ColorPalette.red }}
                        onClick={() => OnDeleteClickHandler(item)}
                        title="Remover da lista de espera"
                        />
                      </div>
                      </td>
                    </tr>
                    );
                  })
                  )}
                </tbody>
                </table>
              </div>
              </div>
            </div>
            );
}

export default WaitingList;
