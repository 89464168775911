import React, { useEffect, useState, useRef } from 'react';
import PopupBase from "../base/PopupBase.js";
import CustomButton from "../../components/buttons/CustomButton.js";
import IconNegative from '@mui/icons-material/Cancel.js';
import IconPositive from '@mui/icons-material/Done.js';

function ConfirmPopup(props) {

    const { Visibility, Title, Description, OnConfirm, OnCancel, OnHide } = props;

    useEffect(() => {
       
    }, []);

    function OnCloseClickHandler() {
        Hide();
    }

    function OnConfirmClickHandler()
    {
        OnConfirm();
        Hide();
    }

    function Hide() 
    {
        OnHide();
    }

    function GetFooter() {
        return (
            <div style={{ display: "flex", flex: "1", width:"100%", justifyContent:"space-between"}}>
                <CustomButton variant="outlined" style="secondary" label={"Cancelar"} icon={<IconNegative />} onClick={OnCloseClickHandler} />
                <CustomButton variant="contained" style="warning" label={"Confirmar"} icon={<IconPositive />} onClick={OnConfirmClickHandler} />
            </div>
        );
    }

    function GetContent() {
        return <p>{Description}</p>;
    }

    return Visibility ? (
        <PopupBase Title={Title} Content={GetContent()} Footer={GetFooter()} OnClose={OnCloseClickHandler} />
    ) : (
        <></>
    );
}

export default ConfirmPopup;