import React, { useEffect, useState } from 'react';
import PopupBase from "../base/PopupBase";
import CustomButton from "../../components/buttons/CustomButton";
import IconSave from '@mui/icons-material/SaveAltOutlined';
import { DataProviderInstance } from "../../api/DataProvider";
import InputField from "../../components/inputfield/InputField";
import { LocalizationProvider, TimeField, TimePicker, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";


function CreateCranialAsymmetryEntryPopup(props) {

    const { patientId, appointmentId, OnHide } = props;
    const { CompanyConfig, SetPatientCranialAsymmetry } = DataProviderInstance();

    const formatDate = (date) => {
        return dayjs(date).set('hour', dayjs().hour()).set('minute', dayjs().minute()).set('second', dayjs().second()).format('YYYY-MM-DD HH:mm:ss');
    }

    const [date, setDate] = useState(formatDate(dayjs()));
    const [apValue, setAPValue] = useState("");
    const [bpValue, setBPValue] = useState("");
    const [ddValue, setDDValue] = useState("");
    const [deValue, setDEValue] = useState("");

    const inputAPref = React.createRef(null);
    const inputBPRef = React.createRef(null);
    const inputDDRef = React.createRef(null);
    const inputDERef = React.createRef(null);

    function OnCloseClickHandler() {
        Hide(false);
    }

    function Hide(needsPageReload) {
        OnHide(needsPageReload);
    }

    function saveHandler() {

        if (Validate()) {
            const entry = {
                ap: apValue,
                bp: bpValue,
                dd: ddValue,
                de: deValue
            };

            SetPatientCranialAsymmetry(date, patientId, appointmentId, entry.ap, entry.bp, entry.dd, entry.de, (response) => {
                Hide(true);
            });
        }
    }

    function Validate() {
        return inputAPref.current.Validate() && inputBPRef.current.Validate() && inputDDRef.current.Validate() && inputDERef.current.Validate() && date != null;
    }

    function GetFooter() {
        return (
            <div style={{ display: "flex", gap: "10px" }}>

                <CustomButton
                    variant="outlined"
                    style="secondary"
                    label={"Cancelar"}
                    onClick={() => Hide(false)}
                />

                <CustomButton
                    style="primary"
                    variant="contained"
                    label={"Registrar"}
                    onClick={saveHandler}
                />
            </div>
        );
    }

  
    const handleDateChange = (newValue) => {
        if (newValue != null) {
            const formattedDate = formatDate(newValue)
            setDate(formattedDate);
        }
        else {
            setDate(null);
        }
    }

    function GetContent() {

        return (
            <div style={{ display: "flex", flexDirection: "column", gap: "50px", flexWrap: "wrap", marginTop: "10px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs} >
                    <DatePicker
                        label="Data da medição"
                        defaultValue={dayjs()}
                        format='DD/MM/YYYY'
                        disableFuture={true}
                        onChange={(newValue) => handleDateChange(newValue)}
                    />
                </LocalizationProvider>

                <div style={{ display: "flex", flexDirection: "row", gap: "10px", flexWrap: "wrap" }}>
                    <InputField type={"number"} Mandatory={true} Value={apValue} Label={"AP"} ref={inputAPref} OnUpdateValue={(value) => setAPValue(value)} />
                    <InputField type={"number"} Mandatory={true} Value={bpValue} Label={"BP"} ref={inputBPRef} OnUpdateValue={(value) => setBPValue(value)} />
                </div>
                <div style={{ display: "flex", flexDirection: "row", gap: "10px", flexWrap: "wrap" }}>
                    <InputField type={"number"} Mandatory={true} Value={ddValue} Label={"DD"} ref={inputDDRef} OnUpdateValue={(value) => setDDValue(value)} />
                    <InputField type={"number"} Mandatory={true} Value={deValue} Label={"DE"} ref={inputDERef} OnUpdateValue={(value) => setDEValue(value)} />
                </div>
            </div>
        )
    }

    return <PopupBase Title={"Adicionar medidas"} Content={GetContent()} Footer={GetFooter()} OnClose={OnCloseClickHandler} />
}

export default CreateCranialAsymmetryEntryPopup;