import React from 'react';
import styles from './ListItem.module.css';
import CustomButtom from '../../../../../components/buttons/CustomButton';
import { Visibility } from '@mui/icons-material';

const ListItem = ({ id, date, title, onSelect }) => {

    const formatDate = (dateString) => {
        const [year, month, day] = dateString.split('-');
        return `${day}/${month}/${year}`;
    };

    const formatTime = (dateString) => {
        const date = new Date(dateString);
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    const handleViewDetails = () => {
        onSelect(id);
    }

    return (
        <div className={styles.container}>
            <div className={styles.infos}>
                <p>{formatDate(date)}</p>
                <p>{title}</p>
            </div>
            <CustomButtom style={"secondary"} variant="outlined" icon={<Visibility />} onClick={handleViewDetails} />
        </div>
    );
};

export default ListItem;