import React from 'react';
import "./Header.css";
import CalendarConfig from "../../../consts/CalendarConfig";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faClock } from '@fortawesome/free-regular-svg-icons';

function Header (props)
{
    let dateStr = CalendarConfig.GetDDMMAAAA(props.Date);

    return (
      <div className='appointment-header-container'>
            <div className='appointment-header-item'> <FontAwesomeIcon  className="appointment-header-icon" icon={faCalendar}/> <span>{CalendarConfig.GetFullDateName(dateStr)}</span></div>
            <div className='appointment-header-item'> <FontAwesomeIcon  className="appointment-header-icon" icon={faClock}  /> <span>{CalendarConfig.GetTime(props.Date)}</span></div>
      </div>
    );
}

export default Header;
