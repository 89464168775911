import React, { useEffect, useState } from 'react';
import styles from './AppointmentInfo.module.css';
import CalendarConfig from '../../../../consts/CalendarConfig';
import CircleIcon from '../../../../components/circleIcon/CircleIcon';
import Icon from "@mui/icons-material/AccessTime";
import AreaTitle from '../../../../components/areaTitle/AreaTitle';
import { DataProviderInstance } from '../../../../api/DataProvider';
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { GetCurrentAge } from '../../../../utils/Utils';

const AppointmentInfo = ({ data, patientData, enabled, elapsedTime, button, onModeChange }) => {

  const
    {
      GetProcedureById
    } = DataProviderInstance();

  const modes = [
    {
      id: 0,
      label: "Adulto",
    },
    {
      id: 1,
      label: "Pediátrico",
    }
  ];

  const getModeByAge = () => {

    if (!patientData.birthday || patientData.birthday == "") {
      return modes[0];
    }
    
    const [day, month, year] = patientData.birthday.split('/');
    const date = new Date(`${year}-${month}-${day}`);

   const age = GetCurrentAge(date);

    if( age < 18)
    {
      return modes[1];
    }

    return modes[0];

  }

  const [currentMode, setCurrentMode] = useState(getModeByAge());

  useEffect(() => {
    onModeChange(currentMode);
  }, [currentMode]);

  function GetElapsedTime(currentSeconds) {
    let hours = Math.floor(currentSeconds / 3600);
    let minutes = Math.floor(currentSeconds / 60);
    let seconds = currentSeconds % 60;

    let formattedHours = hours.toString().padStart(2, '0');
    let formattedMinutes = minutes.toString().padStart(2, '0');
    let formattedSeconds = seconds.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  const secondValue = data.date.split(" ")[1].split(":").slice(0, 2).join(":");

  const description = () => {
    return CalendarConfig.GetFullDateName(CalendarConfig.GetDDMMAAAA(data.date)) + " : " + secondValue;
  }

  const handleModeChange = (event) => {
    const selectedValue = event.target.value;
    const selectedMode = modes.find(
      (item) => item.id === selectedValue
    );
    setCurrentMode(selectedMode);
  }

  return (
    <div className={styles.container}>
      <div style={{ display: "flex", flexDirection: "row", flex: "1", gap: "20px", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap" }}>
        <div style={{ display: "flex", flexDirection: "row", flex: "1", gap: "20px", flexWrap: "wrap", alignItems: "center" }}>
          <AreaTitle Title={"Consulta : " + GetProcedureById(data.procedure).Label} Description={description()} Icon={<Icon />} />      
          {enabled && <div className={styles.timer}>{GetElapsedTime(elapsedTime)}</div>}
        </div>

        <div style={{ display: "flex", flexDirection: "row", flex: "1", gap: "20px", flexWrap: "wrap", alignItems: "center" }}>
          Moldelo de atendimento:
            <FormControl>
              <Select
                defaultValue={currentMode.id}
                onChange={handleModeChange}
              >
                {modes.map((item) => (
                  <MenuItem key={item.label} value={item.id}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        {button}
      </div>
    </div>
  );
};

export default AppointmentInfo;
