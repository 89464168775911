import React from "react";
import './OptionItem.css';

function OptionItem(props)
{
    const {Id,OnClick, Icon, Label, Actived}  = props;

    function OnClickHandler()
    {
        OnClick(Id);
    }

    return(
        <div className={(Actived? "option-item-container-actived" : "option-item-container")} onClick={OnClickHandler}>
            {Icon}{Label}
        </div>
    )
}

export default OptionItem;
