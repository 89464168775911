import React from 'react';
import styles from './TabBarItem.module.css';

const TabBarItem = ({ index, label, actived, onClick }) => {

    const handleItemClick = () => {
        onClick(index);
    }

    return (
        <div className={actived ? styles.actived : styles.default} onClick={handleItemClick} >
            <span>{label}</span>
        </div>
    );
};

export default TabBarItem;