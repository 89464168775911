import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './HistoryItem.module.css';
import CustomButtom from '../../../../../../components/buttons/CustomButton';
import AppointmensState from '../../../../../../consts/AppointmentsState';
import { DataProviderInstance } from "../../../../../../api/DataProvider";
import { Visibility } from '@mui/icons-material';
import { Schedule, Check, WarningRounded, QuestionMark } from '@mui/icons-material';


const HistoryItem = ({ data }) => {

    const { CompanyConfig } = DataProviderInstance();
    const navigate = useNavigate();

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const formatTime = (dateString) => {
        const date = new Date(dateString);
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    const handleViewDetails = () => {
        navigate(`/appointment/${data.id}`);
    }

    function getStateIcon() {
        if (data.invoiceId !== "0") {
            return <Check color='primary' />;
        } else if ((data.status === "completed" || data.status === "noshow") && data.invoiceId === "0") {
            return <WarningRounded color='error' />;
        } else {
            return new Date(data.date) < new Date() ? <QuestionMark color='warning' /> : <Schedule color='primary' />;
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.infos}>
                {getStateIcon()}
                <div>
                    <p>{formatDate(data.date)}</p>
                    <p>{formatTime(data.date)}</p>
                </div>
                <p>R$ {data.price}</p>
                <p>{CompanyConfig.Procedures.find(item => item.Id == data.procedure)?.Label}</p>
                <p>{CompanyConfig.Resources.find(item => item.Id == data.resource)?.Label}</p>
            </div>
            <CustomButtom style={"primary"} variant="outlined" icon={<Visibility />} onClick={handleViewDetails} />

        </div>
    );
};

export default HistoryItem;