export class AppointmentModel {
    constructor(id, status, date, patient, resource, procedure, duration, price, invoiceId) 
    {
      this.id = id;
      this.status = status;
      this.date = date;
      this.patient = patient;
      this.procedure = procedure;
      this.resource = resource;
      this.duration = duration;
      this.price = price;
      this.invoiceId = invoiceId;
    }
  }

export class DayAppointmentsModel
{
    constructor(date, appointments)
    {
        this.date = date;
        this.appointments = appointments;
    }
}