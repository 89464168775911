import React from "react";
import './Loading.css';
import { CircularProgress } from '@mui/material';

function Loading() {
    return (
        <div className="loading-container">
            <CircularProgress />
        </div>
    )
}

export default Loading;