import React from 'react';
import style from './DateHistory.module.css';
import CalendarConfig from '../../../../../consts/CalendarConfig';

const DateHistory = ({ id, date, actived, onSelect }) => {
    const handleClick = () => {
        onSelect(id);
    };

    return (
        <div className={style.container} onClick={handleClick}>
            <p className={actived ? style.dateActived : style.date}>{CalendarConfig.GetDDMMAAAA(date)}</p>
        </div>
    );
};

export default DateHistory;