import React from 'react';
import styles from './Signature.module.css';


const Signature = ({ date, name, sign }) => {
    return (
        <div className={styles.container}>
            {sign !="" && <img className={styles.image} src={sign} alt="sign" />}
            <div className={styles.infos}>
                <h4>{name}</h4>
                <h5>{date}</h5>
            </div>
        </div>
    );
};

export default Signature;