import React from "react";
import "./UserDisplay.css";
import IconAvatar from '@mui/icons-material/AccountCircle';

function UserDisplay(props) {

    const {Name, Role, Picture } = props;

    return (
        <div className="userDisplay">
            {Picture ? <img src={Picture} alt="User" className="userDisplayImg" /> :
            <IconAvatar className="userDisplayImg" fontSize="large" />}
          <div className="userDisplayInfos">
                <span>{Name}</span>
                {/* <p>{Role}</p> */}
            </div>
        </div>
    );
}

export default UserDisplay;