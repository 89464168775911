import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import styles from './Entry.module.css';
import InputField from '../../../../components/inputfield/InputField';
import AreaTitle from '../../../../components/areaTitle/AreaTitle';
import AssessmentIcon from "@mui/icons-material/ExpandMore";
import EvolutionIcon from "@mui/icons-material/ExpandLess";


const Entry = forwardRef(({ appointmentId, onAssesmentChange, onEvolutionChange }, ref) => {

    const KEY_ASSESSMENT = appointmentId+"_assessment";
    const KEY_EVOLUTION = appointmentId+"_evolution";

    const [assessment, setAssessment] = useState("");
    const [evolution, setEvolution] = useState("");

    const assessmentInputRef = React.createRef();
    const evolutionInputRef = React.createRef();

    const numLines = 5;

    useEffect(() => {
        const assessment = localStorage.getItem(KEY_ASSESSMENT);
        const evolution = localStorage.getItem(KEY_EVOLUTION);

        if (assessment) {
            onAssesmentChangeHandler(assessment);
        }

        if (evolution) {
            onEvolutionChangeHandler(evolution);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem(KEY_ASSESSMENT, assessment);
        assessmentInputRef.current.SetValue(assessment);
    }, [assessment]);

    useEffect(() => {
        localStorage.setItem(KEY_EVOLUTION, evolution);
        evolutionInputRef.current.SetValue(evolution);
    }, [evolution]);

    useImperativeHandle(ref, () => ({
        Clear() {
            setAssessment("");
            setEvolution("");
            localStorage.removeItem(KEY_ASSESSMENT);
            localStorage.removeItem(KEY_EVOLUTION);
        }
    }));

    function onAssesmentChangeHandler(value) {
        setAssessment(value);
        onAssesmentChange(value);
    }

    function onEvolutionChangeHandler(value) {
        setEvolution(value);
        onEvolutionChange(value);
    }

    return (
        <div className={styles.mainContainer}>
            <div className={styles.itemContainer}>
               <AreaTitle Title={"Queixa"} Description={"Adicione informações sobre o estado atual do paciente."} Icon={<AssessmentIcon/>} />
                <InputField
                    ref={assessmentInputRef}
                    label="Queixa"
                    multiline
                    rows={numLines}
                    OnUpdateValue={onAssesmentChangeHandler}
                />
            </div>
            <div className={styles.itemContainer}>
            <AreaTitle Title={"Evolução"} Description={"Escreva quais procedimentos foram feitos e demais observações."} Icon={<EvolutionIcon />} />
            <InputField
                    ref={evolutionInputRef}
                    label="Evolução"
                    multiline
                    rows={numLines}
                    OnUpdateValue={onEvolutionChangeHandler}
                />
            </div>
        </div>
    );
});

export default Entry;