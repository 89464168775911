import React from 'react';
import styles from './MainPainItem.module.css';
import Icon from "@mui/icons-material/Close";

const MainPainItem = ({ label, enabled,  OnRemove }) => {

    function OnClickHandler()
    {
        if (OnRemove)
            OnRemove(label);
    }

    return (
        <div className={styles.container} >
           {label}
           {enabled && <Icon fontSize='small' onClick={OnClickHandler} style={{cursor:"pointer"}}/> }
        </div>
    );
};

export default MainPainItem;
