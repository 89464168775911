import React, { useEffect, useState, useRef } from 'react';
import './WhatsappMessages.module.css';
import { GetWhatsappMessages, SendWhatsappMessage } from '../../api/API.js';
import { TextField, Button } from '@mui/material';

function WhatsappMessages() {
  
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const send = async (date, to, text) => {
    console.log('Sending message:', to, text);
   
    try {
      await SendWhatsappMessage(to, text);
      console.log('Message sent:', to, text);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleSendMessage = () => {
    send(Date.now(), phone, message);
  };

  return (
    <div className='page-content' >
       <h2>Whatsapp Messages</h2>
      <div style={{ display: 'flex', gap:"20px" }}>
     
      <>
        <TextField
          label="Whatsapp Number"
          variant="outlined"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <TextField
          label="Message"
          variant="outlined"
          multiline
          rows={4}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <Button variant="contained" color="primary" onClick={handleSendMessage}>
          Send Message
        </Button>
      </>
      </div>
    </div>
  );
}

export default WhatsappMessages;
