import React, { useEffect } from "react";
import SidebarItem from './SidebarItem';
import './SidebarGroup.css';
import AppConfig from "../../consts/AppConfig";

function SidebarGroup(props) {
    const { Label, Items, IsOpen } = props;

    return (
        <div>
            <div className="sidebar-group-title">{Label}</div>
            <div className="sidebar-group">
                {
                    Items.map(item => {
                        const data = AppConfig.GetRouteById(item);
                        return (
                            <SidebarItem key={data.path} path={data.path} icon={data.icon} label={data.label} isOpen={IsOpen} />
                        )
                    })}
            </div>
        </div>
    )
}

export default SidebarGroup;
