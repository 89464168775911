import React from 'react';
import './DayHeader.css';
import ProgressCircle from '../../../../components/progress-circle/ProgressCircle';
import CalendarConfig from '../../../../consts/CalendarConfig';

function DayHeader(props) {
    
    function GetMonthLabel(monthIndex) {
        var string = CalendarConfig.Months[monthIndex];
        return string.substring(0, 3);
    }

    const { Actived, IsToday, day, dayOfWeek, month, percentage } = props;

    let totalStrokeColor = IsToday ? "#eaeaea" : "#eaeaea";
    let currentStrokeColor = IsToday ? "#206afa" : "#206afa";

    function GetContainerClass() {
        if (IsToday)
            return "day-header-today";
        else
            return "day-header";
    }

    return (
        <div className={GetContainerClass()}>
                <div className={IsToday ? "day-active" : "day"}>{day < 10 ? `0${day}` : day}</div>
                 {/* <div className={IsToday ? "dayOfWeek-active" : "dayOfWeek"}>{GetMonthLabel(month)}</div>*/}
            <div className={IsToday ? "dayOfWeek-active" : "dayOfWeek"}>{dayOfWeek.substring(0, 3)}</div>
            {/*<ProgressCircle percentage={percentage} size={15} currentStrokeWidth={2} totalStrokeWidth={2} currentStrokeColor={currentStrokeColor} totalStrokeColor={totalStrokeColor} />*/}
        </div>
    );
}

export default DayHeader;