import React from 'react';
import TabBarItem from './TabBarItem';
import styles from './TabBar.module.css';

const TabBar = ({ labels, value, onChange}) => {

    const [currentIndex, setCurrentIndex] = React.useState(0);

    const handleItemClick = (index) => {

        setCurrentIndex(index);
        onChange(index);
    }

    return (
        <div className={styles.container}> 
            {labels.map((label, index) => (
                <TabBarItem key={index} index={index} label={label} onClick={handleItemClick} actived={currentIndex === index}/>
            ))}
        </div>
    );
};

export default TabBar;