import React from 'react';
import Popover from '@mui/material/Popover';
import InfoIcon from '@mui/icons-material/Info';
import styles from './styles.module.css';

const Info = ({ description }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div>
            <InfoIcon
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                fontSize='verysmall'
                htmlColor="gray"
                className={styles.icon}
            />
            <Popover
                id="mouse-over-popover"
                sx={{ pointerEvents: 'none' }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <div className={styles.box}>
                    <span>{description}</span>
                </div>
            </Popover>
        </div>
    );
};

export default Info;