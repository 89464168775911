import React, { useState } from 'react';
import style from './AnamneseInfo.module.css';
import CalendarConfig from '../../../../../consts/CalendarConfig';

const AnamneseInfo = ({ data }) => {

    const list = JSON.parse(data.anamnese);

    function render() {
        return (
            <div className={style.container}>               
                <div className={style.list}>
                {/*<p className={style.date}>{CalendarConfig.GetDDMMAAAA(data.createdAt)}</p>*/}
                    {list.map((item, index) => (
                        <div key={index} className={style.item}>
                            <h5 className={style.question}> {item.label}</h5>
                            <p className={style.answer}> {item.value}</p>
                            {item.observation && <span className={style.obs}><b>Obs:</b> {item.observation}</span>}
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    return (
        <div className={style.container} >
            {render()}
        </div>
    )
};

export default AnamneseInfo;