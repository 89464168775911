import React, {useState} from 'react';
import styles from './Documents.module.css';
import ListItem from './list/ListItem';

import ViewDocumentPopup from '../../../../popup/ViewDocumentPopup/ViewDocumentPopup';

const Documents = ({ patientData, list }) => {

    const [currentDocumentData, setCurrentDocumentData] = useState(null);

    const selectItemHandler = (id) => {
        setCurrentDocumentData(list.find(item => item.id == id));
    }

    const OnHide = () => {
        setCurrentDocumentData(null);
    }

    return (
        <div className={styles.container}>
            <h3>Documentos emitidos</h3>
            <div className={styles.containerList}>
            {list.map((item, index) => (
                <ListItem key={index} id={item.id} date={item.date} title={item.title} onSelect={selectItemHandler} />
            ))}
            </div>
           {currentDocumentData && <ViewDocumentPopup data={currentDocumentData} patientData={patientData} OnHide={OnHide}/> }
        </div>
    );
};

export default Documents;