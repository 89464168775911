export const breakLineTag = "<br />";
export const whatsAppBreakLineTag = "%0a";
export const textAppBreakLineTag = "\n";

const MessagesTemplate = {

    ConvertToWhatsAppText: (message) => {
        let rtn = message.replaceAll(breakLineTag, whatsAppBreakLineTag);
        return rtn;
    },

    ConvertToClipboardText: (message) => {
        let rtn = message.replaceAll(breakLineTag, textAppBreakLineTag);
        return rtn;
    },

    AppointmentCreatedFullMessage: "{name}, sua consulta está agendada para o dia:" + breakLineTag + breakLineTag +
        "*{day}* de *{month}*, *{when}*, às {time} horas." + breakLineTag + breakLineTag +
        "O valor de cada consulta é de R$ 230 para pagamentos via PIX e R$ 245 no cartão de crédito ou débito. Cada consulta é única e cobrada individualmente, não trabalhamos com retornos." + breakLineTag + breakLineTag +
        "O consultório está localizado no seguinte endereço" + breakLineTag +
        "*Rua Palestina, 359, Vila Mascote, São Paulo, SP*" + breakLineTag + breakLineTag +
        "Aconselhamos vir com roupas confortáveis para a consulta." + breakLineTag + breakLineTag +
        "Caso haja algum imprevisto, não deixe de nos avisar com antecedência." + breakLineTag + breakLineTag +
        "*Nota:* Se você tiver alguma limitação de locomoção ou necessidade especial, por favor, nos avise com antecedência para que possamos fornecer o suporte adequado, pois nosso consultório tem um lance de escadas." + breakLineTag + breakLineTag +
        "Muito obrigada e tenha um excelente dia.",

    AppointmentCreatedShortMessage: "{name}, sua nova consulta está agendada para o dia:" + breakLineTag + breakLineTag +
        "*{day}* de *{month}*, *{when}*, às {time} horas." + breakLineTag + breakLineTag +
        "O valor de cada consulta é de R$ 230 para pagamentos via PIX e R$ 245 no cartão de crédito ou débito. Cada consulta é única e cobrada individualmente, não trabalhamos com retornos." + breakLineTag + breakLineTag +
        "Caso haja algum imprevisto, não deixe de nos avisar com antecedência." + breakLineTag + breakLineTag +
        "Muito obrigada e tenha um excelente dia.",

    AppointmentAskForConfirmation: [
        "{greetings} {name}!" + breakLineTag +
        "Gostaríamos de confirmar sua consulta com a Dra. Juliana Nakashima marcada para *{when}*, *{day}* de *{month}* às {time} horas." + breakLineTag + breakLineTag +
        "Devido à grande procura por horários, é essencial sua confirmação para garantir o seu agendamento." + breakLineTag + breakLineTag +
        "Podemos confirmar a sua presença?",

        "{name}, estamos aguardando a confirmação de sua consulta com a Dra. Juliana Nakashima, marcada para *{when}*, *{day}* de *{month}* às {time} horas." + breakLineTag + breakLineTag +
        "Devida a alta procura por horários disponíveis na agenda da Dra. Juliana Nakashima, é importante que confirme sua presença o quanto antes para manter seu agendamento." + breakLineTag + breakLineTag +
        "Podemos confirmar a sua presença?",

        "{name}, sobre sua consulta com a Dra. Juliana Nakashima está marcada para *{when}*, *{day}* de *{month}* às {time} horas." + breakLineTag + breakLineTag +
        "Por favor, confirme sua presença dentro dos próximos 30 minutos. Caso contrário, teremos que disponibilizar seu horário para outro paciente interessado" + breakLineTag + breakLineTag +
        "Obrigada"
    ],

    Appointment7DaysReminder: "{greetings}! Esse é um lembrete referente a sua consulta com a Dra. Juliana Nakashima, marcada para o dia *{day}* de *{month}* às {time} horas." + breakLineTag + breakLineTag +
        "Se por algum motivo não puder comparecer, pedimos que nos avise com antecedência para que possamos disponibilizar o horário a outros pacientes que estão na lista de espera." + breakLineTag + breakLineTag +
        "Enviaremos um pedido de confirmação de presença 1 dia antes da sua consulta." + breakLineTag + breakLineTag +
        "Aguardamos você!",

    AppointmentCancellationDueLackOfReturn: "{name}, devido à falta de confirmação em resposta às nossas diversas tentativas de contato, sua consulta com a Dra. Juliana Nakashima foi cancelada." + breakLineTag + breakLineTag +
        "Se desejar reagendar uma nova consulta, estaremos à disposição para encontrar uma nova disponibilidade.",

    SuggestDate: "A próxima data disponível para agendar uma consulta com a Dra. Juliana Nakashima é dia" + breakLineTag + breakLineTag +
        "*{day} de {month}*, {when}, às *{time} horas*." + breakLineTag + breakLineTag +
        "Você gostaria de agendar sua consulta para esse dia?",

    HappyBirthday: "Bom dia {name}!" + breakLineTag +
        "Em nome da *Dra. Juliana Nakashima*, Fisioterapeuta Osteopata, e toda sua equipe, queremos desejar a você um *Feliz Aniversário!*" + breakLineTag + breakLineTag +
        "*Que este novo ano seja cheio de saúde, alegria e muito bem-estar*." + breakLineTag + breakLineTag +
        "Estamos aqui para ajudar você a viver cada dia com mais qualidade e bem-estar." + breakLineTag + breakLineTag +
        "Se precisar de algo para iniciar este ciclo com o pé direito, conte conosco." + breakLineTag + breakLineTag +
        "Parabéns!" + breakLineTag + breakLineTag +
        "Atenciosamente," + breakLineTag +
        "Equipe Dra. Juliana Nakashima",

    SuggestDateFromWaitingList: "Olá {name}, temos uma nova disponibilidade para consulta com a Dra. Juliana Nakashima no dia" + breakLineTag +
        "*{day} de {month}*, {when}, às *{time} horas*." + breakLineTag + breakLineTag +
        "Você gostaria de agendar sua consulta para esse dia?"

}

export default MessagesTemplate;