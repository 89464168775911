import React, { useEffect, useState } from 'react';
import styles from './AnamneseHistory.module.css';
import IconAnamnese from '@mui/icons-material/Assignment';
import IconAdd from '@mui/icons-material/Add';
import CustomButton from '../../../../components/buttons/CustomButton';
import CircleIcon from '../../../../components/circleIcon/CircleIcon';
import Icon from "@mui/icons-material/WarningAmberRounded";
import CreateAnamnesePopupContent from '../../../../popup/CreateAnamnesePopupContent/CreateAnamnesePopupContent';
import AnamneseInfo from './anamneseInfo/AnamneseInfo';
import DateHistory from './dateHistory/DateHistory';

const AnamneseHistory = ({ patientId, currentDate, list, enabled, onUpdated }) => {

  const [showCreatePopup, setShowCreatePopup] = useState(false);
  const [currentAnamneseIndex, setCurrentAnamneseIndex] = useState(0);
  const [currentAnamnese, setCurrentAnamnese] = useState(null);

  useEffect(() => {
    updateCurrentAnamnese(currentAnamneseIndex);
  }, [currentAnamneseIndex, list]);

  const updateCurrentAnamnese = (index) => {
    if (list.length > 0) {
      setCurrentAnamnese(list[index]);
    }
  }

  function onCreateAnamneseHandler() {
    showPopup();
  }

  function showPopup() {
    setShowCreatePopup(true);
  }

  function onCreatedAnamneseHandler() {
    if (onUpdated) {
      onUpdated();
    }
  }

  function onItemSelectHandler(index) {
    setCurrentAnamneseIndex(index)
  }

  return (
    <div className={styles.container}>
      <div className={styles.containerHeader}>
        <div style={{ display: "flex", flexDirection: "row", gap: "20px", alignItems: "center" }}>
          {/*list.length == 0 && <CircleIcon style={"alert"} icon={<Icon />} />*/}
          <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <h3>Anamneses</h3>
            {list.length == 0 ? <p>Não existem queixas registradas.</p>
              : <> </>}
          </div>
        </div>
        {enabled && <CustomButton
          variant="outlined"
          style="primary"
          label={""}
          icon={<IconAdd />}
          enabled={enabled}
          onClick={onCreateAnamneseHandler}
        />}
      </div>
      <div className={styles.containerAllInfo}>
        <div className={styles.containerArchive}>
          {list.map((item, index) => (
            <DateHistory key={index} id={index} date={item.createdAt} onSelect={onItemSelectHandler} actived={index == currentAnamneseIndex} />
          ))}
        </div>
        {currentAnamnese && <AnamneseInfo data={currentAnamnese} />}
      </div>
      {showCreatePopup && (
        <CreateAnamnesePopupContent
          currentDate={currentDate}
          patientId={patientId}
          OnSave={onCreatedAnamneseHandler}
          OnHide={() => setShowCreatePopup(false)}
        />
      )}
    </div>
  );
};

export default AnamneseHistory;
