import React, { useEffect } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import IconExpand from '@mui/icons-material/ExpandMore';
import IconCollapse from '@mui/icons-material/ExpandLess';
import ColorPalette from '../../../../colorpalette/ColorPalette';
import style from './FilterList.module.css';
import { Badge, Tooltip } from "@mui/material";

const FilterList = ({ id, label, filters, onChange, initOpened }) => {

    const getLocalStorageInfo = () =>  {
        const localStorageValue = localStorage.getItem(localStorageKey);
        return localStorageValue ? JSON.parse(localStorageValue) : Array.from(filters.map((item, index) => index));
    }

    const localStorageKey = "filterList-"+id;
    const [checkedItems, setCheckedItems] = React.useState(getLocalStorageInfo());
    const [opened, setOpened] = React.useState(initOpened);
    
    const GetFilterNotUsedCount = () => {
        return filters.length-checkedItems.length;
        }

    let message = GetFilterNotUsedCount() == 1? `Existe ${GetFilterNotUsedCount()} item não selecionado` : `Existem ${GetFilterNotUsedCount()} itens não selecionados`;

    useEffect(() => {

         const json = JSON.stringify(checkedItems);
         localStorage.setItem(localStorageKey , json);
        onChange(checkedItems);
    }, [checkedItems]);

    const handleChange = (event) => {
        const index = parseInt(event.target.name);
        if (checkedItems.includes(index)) {
            setCheckedItems(checkedItems.filter(item => item !== index));
        } else {
            setCheckedItems([...checkedItems, index]);
        }
    };

    const isChecked = (index) =>
    {
        return checkedItems.includes(index);
    }

    const onOpenCloseClickHandle = () => {
        setOpened(!opened);
    };

    return (
        <div className={style.container}>
            <div className={style.containerTitle} onClick={onOpenCloseClickHandle}>
                <div style={{display:"flex", flexDirection:"row", gap:"20px"}}>
                    {label}
                    <Tooltip title={message} arrow>
                        <Badge badgeContent={GetFilterNotUsedCount()}  color="primary" fontSize="small" >
                        </Badge>
                    </Tooltip>
                </div>
                {opened ? <IconCollapse /> : <IconExpand />}
            </div>
            {opened && <div className={style.containerItems}>
                {filters.map((item, index) => (
                    <FormControlLabel
                        key={index}
                        label={<span style={{ fontSize: '14px'}}>{item}</span>}
                        control={
                            <Checkbox
                             checked={isChecked(index)}
                             sx={{
                                '& .MuiSvgIcon-root': { fontSize: 14 }, color: ColorPalette.textDark,
                                '&.Mui-checked': {
                                    color: ColorPalette.secondary,
                                }
                            }}
                                onChange={handleChange}
                                name={index}
                            />}
                    />
                ))}
            </div>}
        </div>
    );
};


export default FilterList;