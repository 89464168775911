import React, { useEffect, useState } from 'react';
import AppointmensState from '../../../consts/AppointmentsState';
import "./DayAppointments.css";
import DayHeader from './dayheader/DayHeader';
import HourSlotItem from '../items/HourSlotItem';
import HourSlotDisabledItem from '../items/HourSlotDisabledItem';
import CalendarConfig from '../../../consts/CalendarConfig';
import { DataProviderInstance } from '../../../api/DataProvider';
import CurrentTime from '../hours/currentTime/CurrentTime';

function DayAppointments(props) {
  // Data is a DayAppointmentsData;
  const { Data, OnSelect } = props;
  const { CompanyConfig } = DataProviderInstance();

  const [sourceList, setSourceList] = useState(Data);

  const date = new Date(sourceList.date);
  const IsToday = date.toLocaleDateString() == new Date().toLocaleDateString();

  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    setSourceList(Data);
  }, [Data]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);


  function GetDay() {
    return date.getDate();
  }

  function GetDayOfWeek() {
    return CalendarConfig.DaysOfWeek[date.getDay()];
  }

  function isActive() {
    if (CompanyConfig.AvailableDaysOfWeek.includes(date.getDay()))
      return true;
    else
      return false;
  }

  function OnItemSelectHandler(data) {
    OnSelect(data);
  }


  function GetPercentage() {
    let totalItems = sourceList.appointments.length;

    let availableItems = sourceList.appointments.filter(item => item.status == AppointmensState.Available).length;
    let blockedItems = sourceList.appointments.filter(item => item.status == AppointmensState.Blocked).length;
    let unavailableItems = sourceList.appointments.filter(item => item.status == AppointmensState.Unavailable).length;
    let scheduled = sourceList.appointments.filter(item => item.status == AppointmensState.Scheduled).length;
    let confirmed = sourceList.appointments.filter(item => item.status == AppointmensState.Confirmed).length;
    let done = sourceList.appointments.filter(item => item.status == AppointmensState.Done).length;

    let validSpaces = totalItems - (unavailableItems + blockedItems);
    let percentage = ((validSpaces - availableItems) / validSpaces) * 100;

    let targetOccupation = CompanyConfig.TargetOccupation;

    let ocucupation = scheduled + confirmed + done;

    percentage = (ocucupation / targetOccupation) * 100;

    if (ocucupation > targetOccupation)
      percentage = 100;

    return percentage;
  }

  function GetContent() {

    const groupedAppointments = [...sourceList.appointments].reduce((acc, appointment) => {
      const date = new Date(appointment.date);
      const hour = date.getHours();
      const minutes = date.getMinutes();
      const time = `${hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

      if (!acc[time]) {
        acc[time] = [];
      }
      acc[time].push(appointment);

      return acc;
    }, {});

    const appointmentList = Object.entries(groupedAppointments).map(([time, appointments]) => ({
      time: time,
      appointments: [...appointments]
    }));

    if (isActive()) {
      return (
        <div id="currentDayContainer" className={IsToday ? "day-appointment-today" : "day-appointment"}>
          <DayHeader  Actived={true} IsToday={IsToday} day={GetDay()} dayOfWeek={GetDayOfWeek()} month={date.getMonth()} percentage={GetPercentage()} />
          <div  className='day-appointments-container'>
            {appointmentList.map(item => (<HourSlotItem key={date + item.time} appointments={item.appointments} isToday={IsToday} time={item.time} currentTime={currentTime} OnSelect={OnItemSelectHandler} />))}
          </div>
        </div>
      );
    }
    else {
      return (
        <div className={IsToday ? "day-appointment-today-disabled" : "day-appointment-disabled"}>
          <DayHeader Actived={false} IsToday={IsToday} day={GetDay()} dayOfWeek={GetDayOfWeek()} month={date.getMonth()} />
          <div className="day-appointments-container">
            {sourceList.appointments.map(appointmentModel => <HourSlotDisabledItem key={appointmentModel.date} />)}
          </div>
        </div>
      );
    }

  }

  if (sourceList) {
    return (GetContent());
  }
  else {
    return <div />
  }
}

export default DayAppointments;