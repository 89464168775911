import React from 'react';
import styles from './ListItem.module.css';

const ListItem = ({ id, date, name, resource, procedure, status }) => {

    const time = new Date(date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const formattedDate = new Date(date).toLocaleDateString('pt-BR');

    return (
        <tr key={id} className={styles.container}>
            <td>{formattedDate}</td>
            <td>{time}</td>
            <td>{status}</td>
            <td>{name}</td>
            <td>{resource}</td>
            <td>{procedure}</td>
        </tr>
    );
};

export default ListItem;