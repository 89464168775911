import React from 'react';
import styles from './PersonInfos.module.css';

const PersonInfos = ({ data }) => {
    return (
        <div className={styles.container}>
            {data.map((item, index) => (
                <div key={index} className={styles.info}>
                    <p>{item.key}</p>
                    <p><b>{item.value}</b></p>
                </div>
            ))}
        </div>
    );
};

export default PersonInfos;