import React from "react";
import {  useState } from 'react';

import OptionItem from "./OptionItem/OptionItem";
import './OptionSelector.css';

function OptionSelector(props){

    const { Options, InitialSelection, OnSelect} = props;

    const [currentSelectedIndex, setCurrentSelectedIndex] = useState(InitialSelection);

    function OnItemClickHandler(index)
    {
        SelectItem(index);
    }

    function SelectItem(index)
    {
        setCurrentSelectedIndex(index);
        OnSelect(index);
    }

    let count = -1;

    return(

        <div className="option-selector-content">
           { Options.map(item=>
            {
               count++;
               return  <OptionItem key={count} Id={count} Label={item.Label} Actived={(currentSelectedIndex == count) ? true : false} OnClick={OnItemClickHandler}/>
            })}
         </div>
    );
}

export default OptionSelector;