import React, { useEffect } from "react";
import AppConfig from "../consts/AppConfig";
import styles from "./Menu.module.css";
import MenuItem from "./MenuItem";
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Menu = ({ OnHide }) => {

    useEffect(() => {
        // Travar o scroll da página
        const body = document.querySelector('body');
        const html = document.documentElement;

        // Travar o scroll
        body.style.overflow = 'hidden';
        html.style.overflow = 'hidden';

        return () => {
            // Liberar o scroll da página
            body.style.overflow = 'auto';
            html.style.overflow = 'auto';
        };
    }, []);

    
    function OnCloseClickHandler() {
        OnHide();
    }

    return (
        <div className={styles.container} >
            <div className={styles.close}>
            <FontAwesomeIcon icon={faClose} onClick={OnCloseClickHandler} />
            </div>
            <div className={styles.list} >
                {AppConfig.MenuRoutes.map((group, index) => {
                    return group.routes.map((item) => {
                        let data = AppConfig.GetRouteById(item);
                        return <MenuItem key={data.path} path={data.path} icon={data.icon} label={data.label} OnHide={OnHide} />;
                    });
                })}
            </div>
        </div>
    );
}


export default Menu;