export const PATH_ROOT = "https://www.maisqueagenda.app.br/";
export const PATH_USER_UPLOADS = PATH_ROOT + "user_uploads";
export const PATH_USER_UPLOADS_USER = PATH_USER_UPLOADS + "/user/{userId}";
export const PATH_USER_UPLOADS_COMPANY = PATH_USER_UPLOADS + "/company/{companyId}";
export const PATH_USER_UPLOADS_COMPANY_LOGO = PATH_USER_UPLOADS_COMPANY + "/logo/";
export const PATH_USER_UPLOADS_COMPANY_SIGNATURES = PATH_USER_UPLOADS_COMPANY + "/signatures/";

const RemotePath = {
    PATH_ROOT: () => PATH_ROOT,
    PATH_USER_UPLOADS: () => PATH_USER_UPLOADS,
    PATH_USER_UPLOADS_USER: (userId) => PATH_USER_UPLOADS_USER.replace("{userId}", userId),
    PATH_USER_UPLOADS_USER_PICTURE: (userId) => PATH_USER_UPLOADS_USER.replace("{userId}", userId),
    PATH_USER_UPLOADS_COMPANY: (companyId) => PATH_USER_UPLOADS.replace("{companyId}", companyId),
    PATH_USER_UPLOADS_COMPANY_LOGO: (companyId) => PATH_USER_UPLOADS_COMPANY_LOGO.replace("{companyId}", companyId) ,
    PATH_USER_UPLOADS_COMPANY_RESOURCE_SIGNATURE: (companyId) => PATH_USER_UPLOADS_COMPANY_SIGNATURES.replace("{companyId}", companyId),
}

export default RemotePath;