import React, { useEffect, useState, useRef } from 'react';
import { DataProviderInstance } from '../../api/DataProvider';
import styles from './Company.module.css';
import TabBar from '../../components/tab-bar/TabBar';
import RegistrationData from './areas/registrationData/RegistrationData';
import GeneralConfig from './areas/generalConfig/GeneralConfig';
function Company() {

  let areas = [
    { id: 0, label: "Dados cadastrais" },
    { id: 1, label: "Configurações gerais" }
  ];

  const [currentArea, setCurrentArea] = useState(areas[0].id);

  function handleAreaChange(newValue) {
    setCurrentArea(newValue);
  };

  function GetArea() {
    switch (currentArea) {
      case 0:
        return (<RegistrationData />);
      case 1:
        return (<GeneralConfig />);
      default:
        return (<div></div>);
    }
  }

  return (
    <div className='page-content'>
      <div className={styles.mainContainer}>
        <TabBar labels={areas.map(item => item.label)} value={currentArea} onChange={handleAreaChange} />
        <div className={styles.containerArea} >
          {GetArea()}
        </div>
      </div>
    </div>
  );
}

export default Company;
