import React from 'react';
import styles from './Footer.module.css';


const Footer = ({ title, address, CNPJ, phone }) => {
    return (
        <div className={styles.container}>
           
          </div>
    );
};

export default Footer;