import { NavLink } from 'react-router-dom';
import React, { useEffect, useState} from 'react';
import AreaTitle from '../../components/areaTitle/AreaTitle';
import CustomButton from '../../components/buttons/CustomButton';
import styles from './Logout.module.css';

function Logout() {
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  function handleLogout() {
    doLogout()
  }
  useEffect(() => {
    if (!isAuthenticated) {
      localStorage.setItem('isAuthenticated', 'false'); // Save authentication in cache
      window.location.href = '/'; // Redirect to the dashboard route
    }
  }, [isAuthenticated]);

  const doLogout = () =>{
    localStorage.removeItem('email');
    localStorage.removeItem('password');
    localStorage.removeItem('token');
   
    setIsAuthenticated(false);
  }

  return (
    <div className='page-content'>
      <div className={styles.container}>
      <AreaTitle Title='Deslogar' Description={"Você realmente desejar sair?"} />
      <CustomButton variant="contained" style="primary" onClick={handleLogout} label='Sair' />
      </div>
    </div>
  );
}

export default Logout;