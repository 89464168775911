import { orange } from "@mui/material/colors";

const ColorPalette = {
   transparent: "transparent",
   white: "#fff",
   black: "#000",
   main: "#206afa",
   mainLight1: "#aed4f5",
   mainLight2: "#7fb3d5",
   mainDark: "#385AA4",
   secondary: "#ed3680",
   secondaryLight:"#ffdfec",
   red: "#e74c3c",
   redLight1:"#f08070",
   orange: "#f39c12",
   green:"#B9C4B0",
   greenLight:"#E2FCD6",
   redLight2:"#f5b7b1",
   textDark:"#273644",
   textLight: "#535353",
   textLight2: "#a3a3a3",
   grayLight: "#f7f7f7",
   grayDark:"#242424"
  };

  export default ColorPalette;