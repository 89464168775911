import React, { useEffect, useState, useRef } from 'react';
import { DataProviderInstance } from '../../../../api/DataProvider';
import styles from './RegistrationData.module.css';
import Loading from '../../../../components/loading/Loading.js';
import InputField from "../../../../components/inputfield/InputField";
import CustomButton from "../../../../components/buttons/CustomButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import ColorPallete from '../../../../colorpalette/ColorPalette';
import AreaTitle from '../../../../components/areaTitle/AreaTitle';
import Button from '@mui/material/Button';
import RemotePaths from '../../../../consts/RemotePaths';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import { Badge, Tooltip } from "@mui/material";

const RegistrationData = () => {

    const getInitialLogoPath = () => {
        if (!CompanyConfig.Logo) {
            return RemotePaths.PATH_USER_UPLOADS_COMPANY_LOGO(CompanyConfig.Id) + "default.png"; // Mudar isso depois
        }
        return RemotePaths.PATH_USER_UPLOADS_COMPANY_LOGO(CompanyConfig.Id) + CompanyConfig.Logo;
    };

    const { CompanyConfig, GetCEPInfo, UpdateCompany } = DataProviderInstance();

    const [editing, setEditing] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [logoPath, setLogoPath] = useState(getInitialLogoPath());
    const [hasChanges, setHasChanges] = useState(false);
    const [changedFields, setChangedFields] = useState({});

    const inputFieldNameRef = useRef(null);
    const inputFieldCNPJRef = useRef(null);
    const inputFieldCEPRef = useRef(null);
    const inputFieldAddressRef = useRef(null);
    const inputFieldNumberRef = useRef(null);
    const inputFieldComplementRef = useRef(null);
    const inputFieldDistrictRef = useRef(null);
    const inputFieldCityRef = useRef(null);
    const inputFieldStateRef = useRef(null);
    const inputFieldCountryRef = useRef(null);
    const inputFieldPhoneRef = useRef(null);
    const inputFieldEmailRef = useRef(null);
    const inputFieldFileRef = useRef(null);

    const [loadingAddress, setLoadingAddress] = useState(false);

    const [name, setName] = useState(CompanyConfig.Name || '');
    const [cnpj, setCnpj] = useState(CompanyConfig.CNPJ || '');
    const [cep, setCep] = useState(CompanyConfig.Cep || '');
    const [address, setAddress] = useState(CompanyConfig.Address || '');
    const [number, setNumber] = useState(CompanyConfig.Number || '');
    const [complement, setComplement] = useState(CompanyConfig.Complement || '');
    const [district, setDistrict] = useState(CompanyConfig.District || '');
    const [city, setCity] = useState(CompanyConfig.City || '');
    const [state, setState] = useState(CompanyConfig.State || '');
    const [country, setCountry] = useState(CompanyConfig.Country || '');
    const [phone, setPhone] = useState(CompanyConfig.Phone || '');
    const [email, setEmail] = useState(CompanyConfig.Email || '');
    const [isBlocking, setIsBlocking] = useState(false);
    const [data, setData] = useState({});

    useEffect(() => {
        const path = selectedFile ? URL.createObjectURL(selectedFile) : getInitialLogoPath();
        setLogoPath(path);
    }, [selectedFile]);

    useEffect(() => {
        setIsBlocking(Object.keys(changedFields).length > 0);
    }, [changedFields]);



    function OnSearchCEPClickHandler() {
        let cep = inputFieldCEPRef.current.GetValue();

        if (cep.trim() !== "") {
            GetFullAdressByCEP(cep);
        }
    }

    function GetFullAdressByCEP(value) {
        setLoadingAddress(true);

        GetCEPInfo(value, (result) => {
            inputFieldAddressRef.current.SetValue(result.logradouro);
            inputFieldDistrictRef.current.SetValue(result.bairro);
            inputFieldCityRef.current.SetValue(result.localidade);
            inputFieldStateRef.current.SetValue(result.uf);
            inputFieldCountryRef.current.SetValue("Brasil");

            setAddress(result.logradouro);
            setDistrict(result.bairro);
            setCity(result.localidade);
            setState(result.uf);
            setLoadingAddress(false);
        });
    }

    function onUpdateValue(setter, field, originalValue) {
        return (value) => {
            setter(value);
            if (value !== originalValue) {
                setHasChanges(true);
                setChangedFields((prev) => ({ ...prev, [field]: true }));
            } else {
                setChangedFields((prev) => {
                    const newFields = { ...prev };
                    delete newFields[field];
                    return newFields;
                });
                setHasChanges(Object.keys(changedFields).length > 0);
            }
        };
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        setHasChanges(true);
    };

    const cancelClickHandler = () => {
        setName(CompanyConfig.Name);
        setCnpj(CompanyConfig.CNPJ);
        setCep(CompanyConfig.Cep);
        setAddress(CompanyConfig.Address);
        setNumber(CompanyConfig.Number);
        setComplement(CompanyConfig.Complement);
        setDistrict(CompanyConfig.District);
        setCity(CompanyConfig.City);
        setState(CompanyConfig.State);
        setCountry(CompanyConfig.Country);
        setPhone(CompanyConfig.Phone);
        setEmail(CompanyConfig.Email);
        setChangedFields({});
        setHasChanges(false);
        setIsBlocking(false);
        setEditing(false);
        clearFile();

        inputFieldNameRef.current.SetValue(CompanyConfig.Name);
        inputFieldCNPJRef.current.SetValue(CompanyConfig.CNPJ);
        inputFieldCEPRef.current.SetValue(CompanyConfig.Cep);
        inputFieldAddressRef.current.SetValue(CompanyConfig.Address);
        inputFieldNumberRef.current.SetValue(CompanyConfig.Number);
        inputFieldComplementRef.current.SetValue(CompanyConfig.Complement);
        inputFieldDistrictRef.current.SetValue(CompanyConfig.District);
        inputFieldCityRef.current.SetValue(CompanyConfig.City);
        inputFieldStateRef.current.SetValue(CompanyConfig.State);
        inputFieldCountryRef.current.SetValue(CompanyConfig.Country);
        inputFieldPhoneRef.current.SetValue(CompanyConfig.Phone);
        inputFieldEmailRef.current.SetValue(CompanyConfig.Email);
    };

    function clearFile() {
        if (inputFieldFileRef.current) {
            inputFieldFileRef.current.value = ''; // Limpa o valor do input
        }
        setSelectedFile(null);
    }

    const saveClickHandler = () => {
        setEditing(false);

        let formData = {};
        formData["logo"] = selectedFile;
        formData["name"] = name;
        formData["cnpj"] = cnpj;
        formData["cep"] = cep;
        formData["address"] = address;
        formData["number"] = number;
        formData["complement"] = complement;
        formData["district"] = district;
        formData["city"] = city;
        formData["state"] = state;
        formData["country"] = country;
        formData["phone"] = phone;
        formData["email"] = email;

        UpdateCompany(formData, (result) => {
            if (result) {
                setChangedFields({});
                setHasChanges(false);
                setIsBlocking(false);
                clearFile();
            }
        }
        );
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <div style={{ display: "flex", flexDirection: "row", gap: "20px", justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
                    <AreaTitle Title="Dados cadastrais" />
                    {Object.keys(changedFields).length > 0 && (
                        <Tooltip title={"Dados alterados"} arrow>
                            <Badge badgeContent={Object.keys(changedFields).length} color="error" fontSize="small" />
                        </Tooltip>
                    )}
                </div>
                <div style={{ display: "flex", gap: "10px" }}>
                    {!editing && <CustomButton
                        label={"Editar"}
                        style={"primary"}
                        variant="contained"
                        onClick={() => setEditing(true)}
                    />}
                    {editing && (Object.keys(changedFields).length > 0 || selectedFile) &&
                        <>
                            <CustomButton
                                label={"Cancelar"}
                                style={"secondary"}
                                variant="outlined"
                                onClick={cancelClickHandler}
                            />
                            <CustomButton
                                label={"Salvar alterações"}
                                style={"primary"}
                                variant="contained"
                                onClick={saveClickHandler}
                            />
                        </>}
                </div>
            </div>
            <div className="form">
                <div className={styles.itemContainer}>
                    <AreaTitle Title={"Geral"}  />
                    <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
                        <img
                            src={logoPath}
                            alt="Logo"
                            style={{ width: "100px", height: "100px" }}
                        />
                        <Button
                            variant="contained"
                            component="label"
                            disabled={!editing}
                        >
                            Carregar arquivo
                            <input
                                ref={inputFieldFileRef}
                                hidden
                                type="file"
                                accept=".png,.jpg,.jpeg,.svg"
                                draggable={true}
                                onChange={handleFileChange}
                            />
                        </Button>
                        <div className="form-group">
                            <div className="form-line">
                                <InputField
                                    Mandatory={false}
                                    Value={name}
                                    Label={"Nome"}
                                    ref={inputFieldNameRef}
                                    OnUpdateValue={onUpdateValue(setName, 'name', CompanyConfig.Name)}
                                    className={changedFields.name ? styles.changedField : ''}
                                    Disabled={!editing}
                                />
                            </div>
                            <div className="form-line">
                                <InputField
                                    Mandatory={false}
                                    Value={cnpj}
                                    Label={"CNPJ"}
                                    ref={inputFieldCNPJRef}
                                    Mask={"99.999.999/9999-99"}
                                    OnUpdateValue={onUpdateValue(setCnpj, 'cnpj', CompanyConfig.CNPJ)}
                                    className={changedFields.cnpj ? styles.changedField : ''}
                                    Disabled={!editing}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.itemContainer}>
                    <AreaTitle Title={"Endereço"} />
                    <div className="form-line">
                        <div style={{ display: "flex", gap: "10px" }}>
                            <InputField
                                Mandatory={false}
                                Value={cep}
                                Label={"CEP"}
                                ref={inputFieldCEPRef}
                                Mask={"99999-999"}
                                OnUpdateValue={onUpdateValue(setCep, 'cep', CompanyConfig.Cep)}
                                className={changedFields.cep ? styles.changedField : ''}
                                Disabled={!editing}
                            />
                            <CustomButton
                                style={"secondary"}
                                variant="outlined"
                                icon={<FontAwesomeIcon icon={faMagnifyingGlass} color={ColorPallete.secondary} style={{ fontSize: '16px' }} />}
                                onClick={OnSearchCEPClickHandler}
                                enabled={editing}
                            />
                        </div>
                    </div>
                    <div className="form-line">
                        <InputField
                            Mandatory={false}
                            Value={address}
                            Label={"Rua"}
                            ref={inputFieldAddressRef}
                            OnUpdateValue={onUpdateValue(setAddress, 'address', CompanyConfig.Address)}
                            className={changedFields.address ? styles.changedField : ''}
                            Disabled={!editing}

                        />
                        <InputField
                            Mandatory={false}
                            Value={number}
                            Label={"Número"}
                            ref={inputFieldNumberRef}
                            OnUpdateValue={onUpdateValue(setNumber, 'number', CompanyConfig.Number)}
                            className={changedFields.number ? styles.changedField : ''}
                            Disabled={!editing}

                        />
                        <InputField
                            Mandatory={false}
                            Value={complement}
                            Label={"Complemento"}
                            ref={inputFieldComplementRef}
                            OnUpdateValue={onUpdateValue(setComplement, 'complement', CompanyConfig.Complement)}
                            className={changedFields.complement ? styles.changedField : ''}
                            Disabled={!editing}

                        />
                    </div>
                    <div className="form-line">
                        <InputField
                            Mandatory={false}
                            Value={district}
                            Label={"Bairro"}
                            ref={inputFieldDistrictRef}
                            OnUpdateValue={onUpdateValue(setDistrict, 'district', CompanyConfig.District)}
                            className={changedFields.district ? styles.changedField : ''}
                            Disabled={!editing}

                        />
                        <InputField
                            Mandatory={false}
                            Value={city}
                            Label={"Cidade"}
                            ref={inputFieldCityRef}
                            OnUpdateValue={onUpdateValue(setCity, 'city', CompanyConfig.City)}
                            className={changedFields.city ? styles.changedField : ''}
                            Disabled={!editing}

                        />
                        <InputField
                            Mandatory={false}
                            Value={state}
                            Label={"Estado"}
                            ref={inputFieldStateRef}
                            OnUpdateValue={onUpdateValue(setState, 'state', CompanyConfig.State)}
                            className={changedFields.state ? styles.changedField : ''}
                            Disabled={!editing}

                        />
                        <InputField
                            Mandatory={false}
                            Value={country}
                            Label={"País"}
                            ref={inputFieldCountryRef}
                            OnUpdateValue={onUpdateValue(setCountry, 'country', CompanyConfig.Country)}
                            className={changedFields.country ? styles.changedField : ''}
                            Disabled={!editing}

                        />
                    </div>
                </div>
                <div className={styles.itemContainer}>
                    <AreaTitle Title={"Contatos"}  />
                    <div className="form-line">
                        <InputField
                            Mandatory={false}
                            Value={phone}
                            Label={"Telefone"}
                            ref={inputFieldPhoneRef}
                            Mask={"(99) 99999-9999"}
                            OnUpdateValue={onUpdateValue(setPhone, 'phone', CompanyConfig.Phone)}
                            className={changedFields.phone ? styles.changedField : ''}
                            Disabled={!editing}

                        />
                    </div>
                    <div className="form-line">
                        <InputField
                            Mandatory={false}
                            Value={email}
                            Label={"Email"}
                            ref={inputFieldEmailRef}
                            OnUpdateValue={onUpdateValue(setEmail, 'email', CompanyConfig.Email)}
                            className={changedFields.email ? styles.changedField : ''}
                            Disabled={!editing}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegistrationData;