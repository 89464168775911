import React from 'react';
import styles from './AppointmentsReminder.module.css';
import AppointmentToReminderItem from './item/AppointmentToReminderItem';
import { DataProviderInstance } from '../../../api/DataProvider';
import CalendarConfig from '../../../consts/CalendarConfig';

const AppointmentsReminder = ({ list }) => {

  const
    {
      GetProcedureById,
      GetResourceById,
      GetPatientById
    } = DataProviderInstance()

  function getItem(item, index) {

    let patientData = GetPatientById(item.patient);
    let time = CalendarConfig.GetTime(item.date);

    let resource = GetResourceById(item.resource);
    let procedure = GetProcedureById(item.procedure);

    return (

      <AppointmentToReminderItem
        key={index}
        id={item.id}
        patientId={item.patient}
        name={patientData.name}
        date={item.date}
        time={time}
        phone={patientData.phone}
        procedure={procedure}
        resource={resource}
      />
    );
  }

  const dateStr = list.length > 0 ? CalendarConfig.GetDDMMAAAA(list[0].date) : "";

  return (
    <div className={styles.listContainer}>
      {list.length === 0 ? <span>Nenhum lembrete de consulta para hoje.</span> :
        <>
          <h4>{CalendarConfig.GetFullDateName(dateStr)}</h4>
          {list.map((item, index) => getItem(item, index))}
        </>
      }
    </div>
  );
}

export default AppointmentsReminder;
