import React, { useRef } from 'react';
import styles from './PatientHistory.module.css';
import PatientHistoryItem from './PatientHistoryItem';

const PatientHistory = ({ today, data, enabled, onAssesmentChange, onEvolutionChange }) => {

  function onAssesmentChangeHandler(value) {
    onAssesmentChange(value);
  }

  function onEvolutionChangeHandler(value) {
    onEvolutionChange(value);
  }

  return (
    <div className={styles.container}>
      <div className={styles.containerHeader}>
        <h3>Histórico</h3>
      </div>
      <div className={styles.containerItems}>
        {data.length == 0 ? <p>Não existem evoluções registradas.</p>
          : <> </>}
        {data.map((item, index) => {
          return (
            <PatientHistoryItem key={index} date={item.createdAt} assessment={item.assessment} evolution={item.evolution} />
          )
        })
        }
      </div>
    </div>
  );
};

export default PatientHistory;
