import React, { useRef, useState, useEffect } from "react";
import PopupBase from "../base/PopupBase";
import { DataProviderInstance } from '../../api/DataProvider';
import CustomButton from "../../components/buttons/CustomButton";
import styles from './CreateAnamnesePopupContent.module.css';
import DynamicForm from "../../components/dynamicForm/DynamicForm";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

function CreateAnamnesePopupContent(props) {
    const { CompanyConfig, SetPatientAnamnese } = DataProviderInstance();

    const { currentDate, patientId, OnSave, OnHide } = props;
    const dynamicFormRef = useRef(null);
    const [selectedTemplate, setSelectedTemplate] = useState(CompanyConfig.AnamneseTemplates[0]);

    useEffect(() => {
        if (dynamicFormRef.current) {
            dynamicFormRef.current.Reset();
        }
    }, [selectedTemplate]);

    function OnCloseClickHandler() {
        Hide();
    }

    function OnCancelClickHandler() {
        Hide();
    }

    function OnConfirmClickHandler() {
        if (dynamicFormRef.current.Validate()) {
            SaveData();
            //Hide();
        } 
    }

    function Hide() {
        OnHide();
    }

    function SaveData() {
        const answers = dynamicFormRef.current.GetValue();
        const json = answers;

        const appointmentId = 0; // not usign appointmentId for now

        SetPatientAnamnese(currentDate, patientId, appointmentId, json, (response) => {

            OnSave();
            Hide();
        });
    }

    function GetTitle() {
        return "Criar nova anamnese";
    }

    function handleTemplateChange(event) {
        const selectedValue = event.target.value;
        const selectedTemplate = CompanyConfig.AnamneseTemplates.find(
            (template) => template.Label === selectedValue
        );
        setSelectedTemplate(selectedTemplate);
    }

    function GetContent() {
        return (
            <div>
                <div className={styles.templatesContainer}>
                    Moldelos:
                    <FormControl>
                        <Select
                            labelId="template-select-label"
                            id="template-select"
                            defaultValue={selectedTemplate.Label}
                            onChange={handleTemplateChange}
                        >
                            {CompanyConfig.AnamneseTemplates.map((template) => (
                                <MenuItem key={template.Label} value={template.Label}>
                                    {template.Label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <DynamicForm
                    key={selectedTemplate ? selectedTemplate.label : "default"}
                    ref={dynamicFormRef}
                    questions={selectedTemplate ? JSON.parse(selectedTemplate.Questions) : []}
                />
            </div>
        );
    }

    function GetFooter() {
        return (
            <div style={{display:"flex", gap:"10px"}}>

                <CustomButton
                    variant="outlined"
                    style="secondary"
                    label={"Cancelar"}
                    onClick={OnCancelClickHandler}
                />

                <CustomButton
                    style="primary"
                    variant="contained"
                    label={"Registrar"}
                    onClick={OnConfirmClickHandler}
                />
            </div>
        );
    }

    function GetRender() {
        return (
            <PopupBase
                Title={GetTitle()}
                Content={GetContent()}
                Footer={GetFooter()}
                OnClose={OnCloseClickHandler}
            />
        );
    }

    return GetRender();
}

export default CreateAnamnesePopupContent;