
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { Available } from './AppointmentsState';
import { Weekend } from '@mui/icons-material';

export const Sunday = "Domingo";
export const Monday = "Segunda-feira";
export const Tuesday = "Terça-feira";
export const Wednesday = "Quarta-feira";
export const Thursday = "Quinta-feira";
export const Friday = "Sexta-feira";
export const Saturday = "Sábado";

export const January = "Janeiro";
export const February = "Fevereiro";
export const March = "Março";
export const April = "Abril";
export const May = "Maio";
export const June = "Junho";
export const July = "Julho";
export const August = "Agosto";
export const September = "Setembro";
export const October = "Outubro";
export const November = "Novembro";
export const December = "Dezembro";


const CalendarConfig = {
    DaysOfWeek: [Sunday, Monday, Tuesday, Wednesday, Thursday, Friday, Saturday],
    WeekendDays: [Sunday, Saturday],
    Months: [January, February, March, April, May, June, July, August, September, October, November, December],
    ViewModes: [
        {
            Label: "Diário",
            Icon: <FontAwesomeIcon icon={faCalendarDay} style={{ fontSize: "1px" }} />,
        },
        {
            Label: "Semanal",
            Icon: <FontAwesomeIcon icon={faCalendarAlt} style={{ fontSize: "1px" }} />,
        }
    ],
    ISOToDate: (isoDate) => {
        var date = new Date(isoDate);

        return date;
    },
    DateToISO: (date) => {
        return date.toISOString();
    },
    GetFullDateName: (textDate) => {
        let date;

        let day = "";
        let month = "";
        let year = "";

        if (textDate.includes("/")) {
            date = textDate.split("/");
            day = date[0];
            month = date[1];
            year = date[2];
        } else if (textDate.includes("-")) {
            date = textDate.split("-");
            day = date[2];
            month = date[1];
            year = date[0];
        } else {
            throw new Error("Invalid date format");
        }

        let result = day + " de " + CalendarConfig.Months[month - 1] + " de " + year;

        return result;
    },
    GetAge: (textDate) => {
        let dateParsed = "";
        let day = "";
        let month = "";
        let year = "";

        if (textDate.includes("/")) {
            dateParsed = textDate.split("/");
            day = dateParsed[0];
            month = dateParsed[1];
            year = dateParsed[2];
        } else if (textDate.includes("-")) {
            dateParsed = textDate.split("-");
            day = dateParsed[2];
            month = dateParsed[1];
            year = dateParsed[0];
        } else {
            throw new Error("Invalid date format");
        }

        let date = new Date(year, month - 1, day);

        let currentDate = new Date();
        let ageInMilliseconds = currentDate - date;

        let years = Math.floor(ageInMilliseconds / (365.25 * 24 * 60 * 60 * 1000));
        let remainingMilliseconds = ageInMilliseconds % (365.25 * 24 * 60 * 60 * 1000);
        let months = Math.floor(remainingMilliseconds / (30.44 * 24 * 60 * 60 * 1000));
        let remainingDays = Math.floor(remainingMilliseconds % (30.44 * 24 * 60 * 60 * 1000) / (24 * 60 * 60 * 1000));

        let result = "";

        if (years > 0) {
            result += years + (years === 1 ? " ano" : " anos");
            if (months > 0) {
                result += ", ";
            }
        }

        if (months > 0) {
            result += months + (months === 1 ? " mês" : " meses");
            if (remainingDays > 0) {
                result += ", ";
            }
        }

        if (remainingDays > 0) {
            result += remainingDays + (remainingDays === 1 ? " dia" : " dias");
        }

        return result;
    },
    GetAAAAMMDD: (date) => {

        let isoDate;

        if (typeof date === 'string') {
            isoDate = date;
        }
        else {
            isoDate = date.toISOString();
        }

        let isoDateWithoutTime = (isoDate.indexOf("T") == -1) ? isoDate.split(' ')[0] : isoDate.split('T')[0];

        return isoDateWithoutTime;
    },
    GetDDMMAAAA: (date) => {

        let isoDate;

        if (typeof date === 'string') {
            isoDate = date;
        }
        else {
            isoDate = date.toISOString();
        }

        let isoDateWithoutTime = (isoDate.indexOf("T") == -1) ? isoDate.split(' ')[0] : isoDate.split('T')[0];

        let splited = isoDateWithoutTime.split('-');
        let result = splited[2] + "/" + splited[1] + "/" + splited[0];

        return result;
    },

    GetAAAAMMDDArray: (date) => {

        let isoDate;

        if (typeof date === 'string') {
            isoDate = date;
        }
        else {
            isoDate = date.toISOString();
        }

        let isoDateWithoutTime = (isoDate.indexOf("T") == -1) ? isoDate.split(' ')[0] : isoDate.split('T')[0];

        let splited = isoDateWithoutTime.split('-');
        let result = splited;

        return result;
    },
    GetDayOfWeek: (date) => {

        let parsedDate = new Date(date.replace(" ", "T"));
        let dayOfWeek = parsedDate.getDay();
        return CalendarConfig.DaysOfWeek[dayOfWeek];
    },
    GetTime: (date) => {

        let isoDate;

        if (typeof date === 'string') {
            isoDate = date;
        }
        else {
            isoDate = date.toISOString();
        }

        let isoDateWithoutTime = (isoDate.indexOf("T") == -1) ? isoDate.split(' ')[1] : isoDate.split('T')[1];

        let splited = isoDateWithoutTime.split(":");
        let hour = splited[0];
        let min = splited[1]

        return hour + ":" + min;
    },
    FormatTime: (date) => {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
    },
    GetHourAndMinFromString: (value) => {
        let splited = value.split(":");
        return splited[0] + ":" + splited[1];
    },
    GetCalendarTimes: (AvailableTime, TimeInterval) => {

        var times = [];

        let itemsCount = (AvailableTime[1] - AvailableTime[0]) * (1 / TimeInterval);

        for (let i = 0; i <= itemsCount; i++) {
            let hour = AvailableTime[0] + (i * TimeInterval);
            let minutes = 60 * (hour - Math.floor(hour));

            let strHour = Math.floor(hour);
            let strMinutes = minutes;

            if (hour < 10)
                strHour = "0" + Math.floor(hour);

            if (minutes < 10)
                strMinutes = "0" + minutes;

            let formatedTime = strHour + ":" + strMinutes;

            times.push(formatedTime);
        }

        return times;
    }
}

export default CalendarConfig;

