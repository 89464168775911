import React from 'react';
import styles from './AppointmentsToConfirm.module.css';
import AppointmentsByDay from './item/AppointmentsByDay';
import CalendarConfig from '../../../consts/CalendarConfig';

const AppointmentsToConfirm = ({ list }) => {

  function AddItem(item, index) {

    const dateStr = CalendarConfig.GetDDMMAAAA(item.date);
    const today = new Date();
    const tomorrow = new Date(today);
    const currentDateStr = CalendarConfig.GetDDMMAAAA(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const tomorrowDateStr = CalendarConfig.GetDDMMAAAA(tomorrow);

    let label = dateStr;

    if (dateStr === currentDateStr) {
      label = "Hoje, " + CalendarConfig.GetFullDateName(dateStr);
    }
    else if (dateStr === tomorrowDateStr) {
      label = "Amanhã, " + CalendarConfig.GetFullDateName(dateStr);
    }
    else
    {
      label = CalendarConfig.GetFullDateName(dateStr);
    }

    return (
      <AppointmentsByDay
        key={index}
        label={label}
        appointments={item.list}
      />
    );
  }

  return (
    <div className={styles.listContainer}>
      {list.map((item, index) => (
        AddItem(item, index)
      ))}
    </div>
  );
}

export default AppointmentsToConfirm;
