export const Patient_State_Active = "Ativo";
export const Patient_State_Inactive = "Inativo";

const PatientConfig = {
    Status:
    [
        {
            id : "0",
            Label: Patient_State_Inactive,
        },
        {
            id : "1",
            Label: Patient_State_Active,
        }
    ],
    GetStateById : (id) =>{
        return PatientConfig.Status.find((item) => {
            return item.id === id;
          })
    },
    Genders:
    [
        {
            id:"0", 
            Label:"Masculino",
        },
        {
            id:"1", 
            Label:"Feminino",
        }
    ],
    GetGenderById : (id) =>{
        return PatientConfig.Genders.find((item) => {
            return item.id == id;
          })
    }
}

export default PatientConfig;