import React from "react";
import PatientConfig from "../../../consts/PatientConfig";
import styles from "./Header.module.css";
import CustomButtom from "../../../components/buttons/CustomButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMessage, faCalendarCheck  } from '@fortawesome/free-solid-svg-icons';
import IconWhatsapp from '@mui/icons-material/WhatsApp';
import Picture from '../../../assets/images/patient-picture.png';
import CalendarConfig from "../../../consts/CalendarConfig";
import { SendWhatsAppMessage } from  "../../../api/WhatsappAPI";
import MessagesTemplate from "../../../consts/MessagesTemplate";

function Header(props) {
    const { Patient } = props;

    const age = Patient.birthday ? CalendarConfig.GetAge(Patient.birthday) : "Idade desconhecida";
    const gender = Patient.gender != null && Patient.gender != "" ? PatientConfig.GetGenderById(Patient.gender).Label : "";

    function OnPhoneClickHandler() {
        window.location.href = `tel:${Patient.phone}`;
    }

    function OnMessageClickHandler() {
        let template = "Olá {name}";
        let message = template;
        message = message.replaceAll("{name}", Patient.name.split(' ')[0]);

        SendWhatsAppMessage(Patient.phone, MessagesTemplate.ConvertToWhatsAppText(message));
    }

    function OnScheduleClickHandler() {

    }

    return (
        <div className={styles.mainContainer}>
            <div className={styles.containerContent}>
                <img src={Picture} width={60} height={60} />
                <div style={{display:"flex", flexDirection:"column", gap:"10px"}}>
                    <h2 className={styles.patientName}>{Patient.name} </h2>
                    <p className={styles.patientGender}> {gender + " (" + age + ")"} </p>
                </div>
            </div>
            <div className={styles.actions}>
                <CustomButtom style={"primary"} variant="outlined" icon={<FontAwesomeIcon icon={faPhone} style={{ color: "#206afa" }} />} onClick={OnPhoneClickHandler} />
                <CustomButtom style={"primary"} variant="outlined" icon={<IconWhatsapp style={{ color: "#206afa" }} />} onClick={OnMessageClickHandler} />
                {/*<CustomButtom id={3} style={"primary"} variant="outlined" icon={<FontAwesomeIcon icon={faCalendarCheck} style={{ color: "#206afa" }} />} onClick={OnScheduleClickHandler} />*/}
            </div>
        </div>
    )
}

export default Header;