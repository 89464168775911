import React from "react";
import styles from "./CircleIcon.module.css";

function CircleIcon({ icon, style }) {
    const styleClass = style ? styles[style] : styles.none;

    return (
        <div className={styleClass}>{icon}</div>
    );
}

export default CircleIcon;