import React,{useEffect, useState} from 'react';
import { DataProviderInstance } from '../../../api/DataProvider';
import styles from './AppointmentItem.module.css';
import AppointmensState from '../../../consts/AppointmentsState';
import IconPaid from '@mui/icons-material/AttachMoneyOutlined';

function AppointmentItem(props) {

  const { appointment, OnSelect } = props;
  const appointmentStyleData = AppointmensState.GetDataFromId(appointment.status);
  const patientData = DataProviderInstance().GetPatientById(appointment.patient);

  const parentDivRef = React.useRef(null);
  const [isHidden, setIsHidden] = React.useState(false);

  useEffect(() => {

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function handleResize() {
    if (parentDivRef.current) {
      const parentWidth = parentDivRef.current.offsetWidth;
      setIsHidden(parentWidth < 40);
    }
  }

  function OnSelectHandler() {
    OnSelect(appointment);
  }

  const backgroundStyle = {
    background: appointmentStyleData.background,
    backgroundColor: appointmentStyleData.backgroundColor,
    borderColor: appointmentStyleData.borderColor !== null ? appointmentStyleData.borderColor : 'transparent',
    cursor: appointmentStyleData.cursor,
    height: appointmentStyleData.height,
    flex: appointmentStyleData.flex !== null ? appointmentStyleData.flex : '1',
  };

  const labelStyle = {
    color: appointmentStyleData.textColor,
  }

  const iconStyle = {
    color: appointmentStyleData.textColor,
  };

  const GetStateIcon = () => {
    return <></>;
    return appointmentStyleData.icon;
  }

  const GetPaidIcon = () => {
    return appointment.invoiceId != "0" ? <IconPaid style={iconStyle} fontSize="verysmall" /> : null;
  }

  const GetAvailableContent = () => {
    return (
      <div className={styles.appointmentItem} style={backgroundStyle} onClick={OnSelectHandler}>
        <div className={styles.label} style={labelStyle}></div>
      </div>
    );
  }

  const GetUnavailableContent = () => {
    return (
      <div className={styles.appointmentItem} style={backgroundStyle} >
        <div className={styles.label} style={labelStyle}></div>
      </div>
    );
  }

  const GetBlockedContent = () => {
    let reason = appointment.patient;
    return (
      <div className={styles.appointmentItem} style={backgroundStyle} onClick={OnSelectHandler}>
        <div className={styles.iconsContainer}> </div>
        <div className={styles.label} style={labelStyle}>{reason}</div>
      </div>
    );
  }

  const GetScheduledContent = () => {

    const stateIcon = GetStateIcon();
    const paidIcon = GetPaidIcon();

    return (
      <div ref={parentDivRef} className={styles.appointmentItem} style={backgroundStyle} onClick={OnSelectHandler}>
        {!isHidden && (
          <>
            <div className={styles.iconsContainer}>
              {stateIcon}
              {paidIcon}
            </div>
            <div className={styles.label} style={labelStyle}>{patientData.name}</div>
          </>
        )}
      </div>
    );
  }

  const GetContent = () => {
    switch (appointment.status) {
      case AppointmensState.Available:
        return GetAvailableContent();
      case AppointmensState.Unavailable:
        return GetUnavailableContent();
      case AppointmensState.Blocked:
        return GetBlockedContent();
      default:
        return GetScheduledContent();
    }
  }

  return GetContent();
}

export default AppointmentItem;